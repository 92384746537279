import {
  Arbitrum,
  Avalanche,
  BSC,
  Optimism,
  Polygon,
  Mainnet,
  Moonbeam,
  Boba,
  Base,
  Gnosis,
  Linea,
} from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import {
  ArbitrumSepolia,
  Blast,
  Filecoin,
  Hardhat,
  Lisk,
  MantaPacific,
  Mantle,
  Metis,
  Mode,
  PolygonZkEVM,
  Rootstock,
  Scroll,
  Sei,
  Taiko,
  ZkSync,
} from './custom-networks';

type UpdaterConfig = {
  batchId: string;
  owner: string;
  name: string;
  topUpAmount?: BigNumber;
  lowBalanceWarning?: BigNumber;
  lowBalanceDanger?: BigNumber;
  disabled?: boolean;
};

type TokenConfig = {
  name: string;
  symbol: string;
  decimals: number;
  icon: string;
};

type DataPointsConfig = {
  price?: {
    label: string;
  };
  tokenLiquidity?: {
    label: string;
  };
  quoteTokenLiquidity?: {
    label: string;
  };
  rate?: {
    label: string;
  };
};

type OracleConfig = {
  hide?: boolean;
  name: string;
  desc?: string;
  purpose?: {
    text: string;
    learnMore?: string;
  };
  type?:
    | 'supplyAndBorrow'
    | 'dexPriceAndLiquidity'
    | 'interestRate'
    | 'gasPrice'
    | 'volatility'
    | 'valueAndError'
    | 'rateController'
    | 'erc20MutationComputer'
    | 'oracleMutationComputer'
    | 'slopedOracleMutationComputer'
    | 'capController'
    | 'pidController'
    | 'rateComputer'
    | 'historicalRatesComputer';
  decimals: number;
  quoteUnit: {
    symbol: string;
  };
  display: {
    ratePrefix?: string;
    rateSuffix?: string;
    iconOverlay?: string;
    chartTitle?: string;
    decimals?: number; // Only used by controllers
    chart?: {
      annotationsX?: {
        [token: string]: {
          [x: number]: {
            label: string;
            txHash?: string;
          };
        };
      };
    };
  };
  updating?: {
    price?: {
      bips: number;
      heartbeat: number; // in seconds
    };
    liquidity?: {
      bips: number;
      heartbeat: number; // in seconds
    };
  };
  tokens: string[];
  dataPoints: DataPointsConfig;
  maxFilterBlocks?: number;
  logsBatchSize?: number;
  permissions?: {
    [key: string]: {
      role: string;
    };
  };
};

type AddressConfig = {
  name: string;
};

type StrategyType = 'validation' | 'aggregation' | 'averaging';

type StrategyConfig = {
  name: string;
  desc?: string;
};

type ChainConfig = {
  name: string;
  chainId: number;
  maxFilterBlocks?: number;
  logsBatchSize?: number;
  defaultTopUpAmount: BigNumber;
  defaultLowBalanceWarning: BigNumber;
  defaultLowBalanceDanger: BigNumber;
  tokens: Record<string, TokenConfig>;
  oracles: Record<string, OracleConfig>;
  controllers?: Record<string, OracleConfig>;
  updaters?: Record<string, UpdaterConfig>;
  addresses?: Record<string, AddressConfig>;
  strategies?: {
    validation?: Record<string, StrategyConfig>;
    aggregation?: Record<string, StrategyConfig>;
    averaging?: Record<string, StrategyConfig>;
  };
  transformers?: {
    inputAndError?: Record<string, StrategyConfig>;
  };
  automatos?: {
    registryFactory: string;
    billingTokens: string[];
    registries?: {
      [key: string]: {
        verified?: boolean;
        servicedBy?: string;
      };
    };
  };
};

type AdrastiaConfig = {
  chains: Record<string, ChainConfig>;
};

type UpdaterBalanceConfig = {
  topUpAmount: BigNumber;
  lowBalanceWarning: BigNumber;
  lowBalanceDanger: BigNumber;
};

type UpdaterBalanceConfigs = {
  [chain: string]: {
    [type: string]: {
      [level: string]: UpdaterBalanceConfig;
    };
  };
};

export const dexPriceAndLiquidity: DataPointsConfig = {
  price: {
    label: 'Price',
  },
  tokenLiquidity: {
    label: 'Token Liquidity',
  },
  quoteTokenLiquidity: {
    label: 'Quote Token Liquidity',
  },
};

export const priceDataPoints: DataPointsConfig = {
  price: {
    label: 'Price',
  },
};

const interestRate: DataPointsConfig = {
  price: {
    label: 'Interest Rate',
  },
};

const gasPrice: DataPointsConfig = {
  price: {
    label: 'Gas Price',
  },
};

const volatility: DataPointsConfig = {
  price: {
    label: 'Volatility',
  },
};

export const supplyAndBorrow: DataPointsConfig = {
  tokenLiquidity: {
    label: 'Total borrow',
  },
  quoteTokenLiquidity: {
    label: 'Total supply',
  },
};

export const utilizationAndError: DataPointsConfig = {
  tokenLiquidity: {
    label: 'Utilization',
  },
  quoteTokenLiquidity: {
    label: 'Error',
  },
};

export const ratesSupplyCap: DataPointsConfig = {
  rate: {
    label: 'Supply cap',
  },
};

export const ratesBorrowCap: DataPointsConfig = {
  rate: {
    label: 'Borrow cap',
  },
};

export const prudentiaGenericDataPoints: DataPointsConfig = {
  rate: {
    label: 'Rate',
  },
};

export const prudentiaInterestRates: DataPointsConfig = {
  rate: {
    label: 'Interest rate',
  },
};

const v4FeedsHidden = false;
const v4p2FeedsHidden = false;

const UPDATER_BALANCE_CONFIGS: UpdaterBalanceConfigs = {
  arbitrumOne: {
    dex: {
      primary: {
        topUpAmount: ethers.utils.parseEther('0.5'),
        lowBalanceWarning: ethers.utils.parseEther('0.2'),
        lowBalanceDanger: ethers.utils.parseEther('0.1'),
      },
      secondary: {
        topUpAmount: ethers.utils.parseEther('0.5'),
        lowBalanceWarning: ethers.utils.parseEther('0.2'),
        lowBalanceDanger: ethers.utils.parseEther('0.1'),
      },
      tertiary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
      quaternary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
    },
    lending: {
      primary: {
        topUpAmount: ethers.utils.parseEther('0.2'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
      secondary: {
        topUpAmount: ethers.utils.parseEther('0.2'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
      tertiary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
      quaternary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
    },
  },
  optimism: {
    dex: {
      primary: {
        topUpAmount: ethers.utils.parseEther('0.5'),
        lowBalanceWarning: ethers.utils.parseEther('0.2'),
        lowBalanceDanger: ethers.utils.parseEther('0.1'),
      },
      secondary: {
        topUpAmount: ethers.utils.parseEther('0.5'),
        lowBalanceWarning: ethers.utils.parseEther('0.2'),
        lowBalanceDanger: ethers.utils.parseEther('0.1'),
      },
      tertiary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
      quaternary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
    },
    lending: {
      primary: {
        topUpAmount: ethers.utils.parseEther('0.2'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
      secondary: {
        topUpAmount: ethers.utils.parseEther('0.2'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
      tertiary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
      quaternary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'), // supports gas prices up to 2 gwei (20M gas)
      },
    },
  },
  polygon: {
    dex: {
      primary: {
        topUpAmount: ethers.utils.parseEther('250'),
        lowBalanceWarning: ethers.utils.parseEther('100'),
        lowBalanceDanger: ethers.utils.parseEther('20'),
      },
      secondary: {
        topUpAmount: ethers.utils.parseEther('250'),
        lowBalanceWarning: ethers.utils.parseEther('100'),
        lowBalanceDanger: ethers.utils.parseEther('20'),
      },
      tertiary: {
        topUpAmount: ethers.utils.parseEther('100'),
        lowBalanceWarning: ethers.utils.parseEther('50'),
        lowBalanceDanger: ethers.utils.parseEther('20'),
      },
      quaternary: {
        topUpAmount: ethers.utils.parseEther('100'),
        lowBalanceWarning: ethers.utils.parseEther('50'),
        lowBalanceDanger: ethers.utils.parseEther('20'),
      },
    },
    gas: {
      primary: {
        topUpAmount: ethers.utils.parseEther('250'),
        lowBalanceWarning: ethers.utils.parseEther('100'),
        lowBalanceDanger: ethers.utils.parseEther('20'),
      },
      secondary: {
        topUpAmount: ethers.utils.parseEther('250'),
        lowBalanceWarning: ethers.utils.parseEther('100'),
        lowBalanceDanger: ethers.utils.parseEther('20'),
      },
      tertiary: {
        topUpAmount: ethers.utils.parseEther('100'),
        lowBalanceWarning: ethers.utils.parseEther('50'),
        lowBalanceDanger: ethers.utils.parseEther('20'),
      },
      quaternary: {
        topUpAmount: ethers.utils.parseEther('100'),
        lowBalanceWarning: ethers.utils.parseEther('50'),
        lowBalanceDanger: ethers.utils.parseEther('20'),
      },
    },
    lending: {
      primary: {
        topUpAmount: ethers.utils.parseEther('20'),
        lowBalanceWarning: ethers.utils.parseEther('10'),
        lowBalanceDanger: ethers.utils.parseEther('5'),
      },
      secondary: {
        topUpAmount: ethers.utils.parseEther('20'),
        lowBalanceWarning: ethers.utils.parseEther('10'),
        lowBalanceDanger: ethers.utils.parseEther('5'),
      },
      tertiary: {
        topUpAmount: ethers.utils.parseEther('20'),
        lowBalanceWarning: ethers.utils.parseEther('10'),
        lowBalanceDanger: ethers.utils.parseEther('5'),
      },
      quaternary: {
        topUpAmount: ethers.utils.parseEther('20'),
        lowBalanceWarning: ethers.utils.parseEther('10'),
        lowBalanceDanger: ethers.utils.parseEther('5'),
      },
    },
  },
  polygonZkEVM: {
    dex: {
      primary: {
        topUpAmount: ethers.utils.parseEther('0.2'),
        lowBalanceWarning: ethers.utils.parseEther('0.2'),
        lowBalanceDanger: ethers.utils.parseEther('0.1'),
      },
      secondary: {
        topUpAmount: ethers.utils.parseEther('0.2'),
        lowBalanceWarning: ethers.utils.parseEther('0.2'),
        lowBalanceDanger: ethers.utils.parseEther('0.1'),
      },
      tertiary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'),
      },
      quaternary: {
        topUpAmount: ethers.utils.parseEther('0.1'),
        lowBalanceWarning: ethers.utils.parseEther('0.05'),
        lowBalanceDanger: ethers.utils.parseEther('0.04'),
      },
    },
  },
};

const CEO_COLLATERAL_PURPOSE = {
  text: `
A Credit Extension Oracle of the collateral type is designed to safeguard the stability and reliability of DeFi protocols by incorporating mechanisms that instantly recognize downward price movements while delaying upward price movements. This approach is crucial for mitigating risks associated with volatile market conditions.
<br><br>
When a collateral asset's price drops, the oracle immediately adjusts its reported value, ensuring that the protocol quickly responds to potential liquidity issues or under-collateralization. This rapid response helps protect the system from sudden market downturns, reducing the risk of defaults and ensuring the continued solvency of the lending platform.
<br><br>
Conversely, the oracle delays the recognition of upward price movements. This conservative approach ensures that the protocol does not prematurely increase the credit limits based on potentially transient or speculative price spikes. By waiting for sustained price increases, the oracle provides a more stable and accurate reflection of the asset's value, promoting long-term security and preventing excessive risk-taking.
<br><br>
Overall, this dual approach of rapid downward price adjustment and delayed upward recognition helps maintain a balanced and secure environment for credit extensions, protecting both lenders and borrowers in the DeFi ecosystem.
`,
  learnMore: 'https://docs.adrastia.io/data-feeds/credit-extension-oracle',
};

const CEO_DEBT_PURPOSE = {
  text: `
A Credit Extension Oracle of the debt type is crucial for maintaining stability and accuracy in decentralized finance (DeFi) systems by providing reliable data on the value of debt assets. This oracle is designed to immediately recognize upward price movements while delaying the recognition of downward price movements, ensuring a strategic and protective approach to debt valuation.
<br><br>
When the value of a debt asset increases, the oracle instantly updates its reported value. This immediate recognition is essential for ensuring that necessary liquidations occur as assets rise in value, thereby protecting lenders. By quickly adjusting to upward price movements, the oracle ensures that the protocol can promptly initiate liquidations to cover outstanding debts, maintaining the system’s solvency and reducing the risk to lenders.
<br><br>
Conversely, the oracle delays the recognition of downward price movements. This conservative approach is designed to protect lenders by preventing borrowers from exploiting temporary or speculative drops in asset values to increase their borrowing capacity. By waiting for sustained decreases in value, the oracle ensures that borrowers are not able to borrow more than they should during short-term price declines. This delayed response helps maintain the integrity and stability of the lending platform, reducing the risk of defaults and under-collateralization.
<br><br>
Overall, this dual mechanism of immediate upward adjustment and delayed downward recognition safeguards lenders and promotes a balanced and secure environment for credit extensions in the DeFi ecosystem, ensuring long-term stability and resilience.
`,
  learnMore: 'https://docs.adrastia.io/data-feeds/credit-extension-oracle',
};

const LIQUIDATION_ORACLE_PURPOSE = {
  text: `
Liquidation oracles, commonly referred to as "oracles" or "price feeds" in the decentralized finance (DeFi) ecosystem, provide critical and timely market price data for various assets. These oracles play a fundamental role in ensuring the stability and security of lending and borrowing platforms by supplying accurate and up-to-date information about asset values.
<br><br>
The primary function of liquidation oracles is to report current market prices, which DeFi platforms use to determine the value of collateral and other assets within their systems. While these oracles do not trigger liquidation events or monitor the value of collateral directly, their data is essential for the processes that manage and assess collateralization levels.
<br><br>
By delivering reliable price information, liquidation oracles enable DeFi platforms to make informed decisions regarding liquidations and other critical operations. This ensures that liquidations are based on precise and current market data, protecting both lenders and borrowers from the risks associated with price volatility.
<br><br>
In essence, liquidation oracles provide the necessary price data that underpins the entire liquidation process, maintaining the integrity and stability of DeFi platforms without directly intervening in the execution of liquidations or collateral assessments.
`,
};

const DAO_PURPOSE = {
  text: `
  The Daily Average Oracle plays a crucial role in the DeFi ecosystem by computing the average price of a crypto asset over a 24-hour period. This stabilized price reference helps protect lenders in volatile markets, ensuring that the protocol remains above water. By smoothing out short-term price fluctuations, the Daily Average Oracle supports other oracles, such as our Credit Extension Oracles, in accurately determining the value of collateral and debt assets during the credit extension (loan issuance) process. This mechanism safeguards lenders by providing a buffer against market volatility, ensuring that credit extensions are based on a more stable price foundation and reducing the risk associated with sudden price swings.  
`,
};

const MEDIAN_DAO_PURPOSE = {
  text: `
The Median-Filtered Daily Average Oracle is designed to provide an even more robust price reference in the DeFi ecosystem by computing the median of numerous daily averages. This approach further protects against sudden and unexpected price swings, which can still somewhat affect simple averages. By requiring price swings to hold for multiple days before being reported, the Median-Filtered Daily Average Oracle ensures a higher level of stability and reliability. This oracle plays a crucial role in supporting other oracles, such as Adrastia's Credit Extension Oracles, during the credit extension (loan issuance) process. By offering a median-filtered price, it helps safeguard lenders by providing a more consistent and dependable valuation of collateral and debt assets, thus enhancing the overall resilience of the lending protocol against market volatility.
`,
};

const globalAddresses: Record<string, AddressConfig> = {
  '0xec89a5dd6c179c345EA7996AA879E59cB18c8484': {
    name: 'Adrastia Admin',
  },
  '0x056c7933D2f25904787bb92C26E9f90655529f8E': {
    name: 'Adrastia Deployer',
  },
};

const config: AdrastiaConfig = {
  chains: {
    ethereum: {
      name: 'Ethereum',
      chainId: Mainnet.chainId,
      defaultTopUpAmount: ethers.utils.parseEther('1.0'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.5'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.1'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {
        '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': {
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          icon: 'usdc',
        },
        '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': {
          name: 'Wrapped Ethereum',
          symbol: 'WETH',
          decimals: 18,
          icon: 'eth',
        },
        '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599': {
          name: 'Wrapped Bitcoin',
          symbol: 'WBTC',
          decimals: 8,
          icon: 'wbtc',
        },
        '0x6b175474e89094c44da98b954eedeac495271d0f': {
          name: 'Dai Stablecoin',
          symbol: 'DAI',
          decimals: 18,
          icon: 'dai',
        },
        '0xc00e94cb662c3520282e6f5717214004a7f26888': {
          name: 'Compound',
          symbol: 'COMP',
          decimals: 18,
          icon: 'comp',
        },
        '0xd909c5862cdb164adb949d92622082f0092efc3d': {
          name: 'Interest Protocol',
          symbol: 'IPT',
          decimals: 18,
          icon: 'ipt',
        },
        '0xd9fcd98c322942075a5c3860693e9f4f03aae07b': {
          name: 'Euler',
          symbol: 'EUL',
          decimals: 18,
          icon: 'eul',
        },
        '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2': {
          name: 'Maker',
          symbol: 'MKR',
          decimals: 18,
          icon: 'mkr',
        },
        '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9': {
          name: 'Aave',
          symbol: 'AAVE',
          decimals: 18,
          icon: 'aave',
        },
        '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984': {
          name: 'Uniswap',
          symbol: 'UNI',
          decimals: 18,
          icon: 'uni',
        },
        '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919': {
          name: 'Rai Reflex Index',
          symbol: 'RAI',
          decimals: 18,
          icon: 'rai',
        },
        '0x0000000000085d4780B73119b644AE5ecd22b376': {
          name: 'TrueUSD',
          symbol: 'TUSD',
          decimals: 18,
          icon: 'tusd',
        },
        '0xdac17f958d2ee523a2206206994597c13d831ec7': {
          name: 'Tether USD',
          symbol: 'USDT',
          decimals: 6,
          icon: 'usdt',
        },
        '0x853d955acef822db058eb8505911ed77f175b99e': {
          name: 'Frax',
          symbol: 'FRAX',
          decimals: 18,
          icon: 'frax',
        },
        '0x4Fabb145d64652a948d72533023f6E7A623C7C53': {
          name: 'Binance USD',
          symbol: 'BUSD',
          decimals: 18,
          icon: 'busd',
        },
        '0x8e870d67f660d95d5be530380d0ec0bd388289e1': {
          name: 'Pax Dollar',
          symbol: 'USDP',
          decimals: 18,
          icon: 'usdp',
        },
        '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0': {
          name: 'Matic Token',
          symbol: 'MATIC',
          decimals: 18,
          icon: 'matic',
        },
        '0x514910771af9ca656af840dff83e8264ecf986ca': {
          name: 'Chainlink',
          symbol: 'LINK',
          decimals: 18,
          icon: 'link',
        },
        '0xD533a949740bb3306d119CC777fa900bA034cd52': {
          name: 'Curve DAO Token',
          symbol: 'CRV',
          decimals: 18,
          icon: 'crv',
        },
        '0xc944e90c64b2c07662a292be6244bdf05cda44a7': {
          name: 'Graph Token',
          symbol: 'GRT',
          decimals: 18,
          icon: 'grt',
        },
        '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f': {
          name: 'Synthetix Network Token',
          symbol: 'SNX',
          decimals: 18,
          icon: 'snx',
        },
        '0x5a98fcbea516cf06857215779fd812ca3bef1b32': {
          name: 'Lido DAO Token',
          symbol: 'LDO',
          decimals: 18,
          icon: 'ldo',
        },
        '0x111111111117dc0aa78b770fa6a738034120c302': {
          name: '1INCH Token',
          symbol: '1INCH',
          decimals: 18,
          icon: '1inch',
        },
        '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': {
          name: 'Yearn Finance',
          symbol: 'YFI',
          decimals: 18,
          icon: 'yfi',
        },
        '0xba100000625a3754423978a60c9317c58a424e3D': {
          name: 'Balancer',
          symbol: 'BAL',
          decimals: 18,
          icon: 'bal',
        },
        '0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72': {
          name: 'Ethereum Name Service',
          symbol: 'ENS',
          decimals: 18,
          icon: 'ens',
        },
        '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2': {
          name: 'SushiToken',
          symbol: 'SUSHI',
          decimals: 18,
          icon: 'sushi',
        },
        '0x408e41876cccdc0f92210600ef50372656052a38': {
          name: 'Republic',
          symbol: 'REN',
          decimals: 18,
          icon: 'ren',
        },
        '0x0d8775f648430679a709e98d2b0cb6250d2887ef': {
          name: 'Basic Attention Token',
          symbol: 'BAT',
          decimals: 18,
          icon: 'bat',
        },
        '0xe41d2489571d322189246dafa5ebde1f4699f498': {
          name: '0x Protocol Token',
          symbol: 'ZRX',
          decimals: 18,
          icon: 'zrx',
        },
        '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b': {
          name: 'Convex Token',
          symbol: 'CVX',
          decimals: 18,
          icon: 'cvx',
        },
        '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828': {
          name: 'UMA Voting Token v1',
          symbol: 'UMA',
          decimals: 18,
          icon: 'uma',
        },
        '0x8290333cef9e6d528dd5618fb97a76f268f3edd4': {
          name: 'Ankr Network',
          symbol: 'ANKR',
          decimals: 18,
          icon: 'ankr',
        },
        '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0': {
          name: 'Frax Share',
          symbol: 'FXS',
          decimals: 18,
          icon: 'fxs',
        },
        '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0': {
          name: 'Liquity USD',
          symbol: 'LUSD',
          decimals: 18,
          icon: 'lusd',
        },
        '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0': {
          name: 'Wrapped liquid staked Ether',
          symbol: 'wstETH',
          decimals: 18,
          icon: 'steth',
        },
        '0xd33526068d116ce69f19a9ee46f0bd304f21a51f': {
          name: 'Rocket Pool Protocol',
          symbol: 'RPL',
          decimals: 18,
          icon: 'rpl',
        },
        '0xae78736cd615f374d3085123a210448e74fc6393': {
          name: 'Rocket Pool ETH',
          symbol: 'rETH',
          decimals: 18,
          icon: 'reth',
        },
      },
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0x2725A0502dCc7ff4844e5Aa42D4d9c9ec036a0A0': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
          topUpAmount: ethers.utils.parseEther('0.2'),
          // Amounts based on 1M gas limit and 100 gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.15'),
          lowBalanceDanger: ethers.utils.parseEther('0.1'),
        },
        '0xC40af3B53a81B935C88dbba58f917958eA7dC813': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
          topUpAmount: ethers.utils.parseEther('0.2'),
          // Amounts based on 1M gas limit and 100 gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.15'),
          lowBalanceDanger: ethers.utils.parseEther('0.1'),
        },
        '0x102E18e76F04b3AFB494319952A538176EFEDbed': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
          topUpAmount: ethers.utils.parseEther('0.2'),
          // Amounts based on 1M gas limit and 100 gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.15'),
          lowBalanceDanger: ethers.utils.parseEther('0.1'),
        },
        '0xC15c98a95A1EC6e548F34164aA016cE4f1a2bFdc': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
          topUpAmount: ethers.utils.parseEther('0.2'),
          // Amounts based on 1M gas limit and 100 gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.15'),
          lowBalanceDanger: ethers.utils.parseEther('0.1'),
        },
      },
    },
    arbitrumOne: {
      name: 'Arbitrum One',
      chainId: Arbitrum.chainId,
      maxFilterBlocks: Math.ceil((3 * 30 * 24 * 60 * 60) / 0.25), // 3 months
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {
        '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': {
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          icon: 'usdc',
        },
        '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': {
          name: 'Wrapped Ethereum',
          symbol: 'WETH',
          decimals: 18,
          icon: 'eth',
        },
        '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f': {
          name: 'Wrapped BTC',
          symbol: 'WBTC',
          decimals: 8,
          icon: 'wbtc',
        },
        '0x0000000000000000000000000000000000000010': {
          name: 'Deposit Interest Rate',
          symbol: '',
          decimals: 18,
          icon: 'fa-piggy-bank',
        },
        '0x0000000000000000000000000000000000000011': {
          name: 'Borrow Interest Rate - Variable',
          symbol: '',
          decimals: 18,
          icon: '',
        },
        '0x0000000000000000000000000000000000000012': {
          name: 'Borrow Interest Rate - Stable',
          symbol: '',
          decimals: 18,
          icon: '',
        },
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
          icon: 'eth',
        },
        '0xEe4c4dcF5C9Ec793860E9D18d9daf037926098e4': {
          name: 'US Dollar',
          symbol: 'USD',
          decimals: 8,
          icon: 'usd',
        },
      },
      oracles: {
        '0x0Eebd0CEd6814126Bd20Aa954B0B8F5cCb67338D': {
          name: 'Credit Extension Oracle: Collateral (USD)',
          desc: 'v4.7.3',
          purpose: CEO_COLLATERAL_PURPOSE,
          maxFilterBlocks: 10_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x3e390C48735E548b3113c3Ce6B6692375E26A5B5': {
          name: 'Credit Extension Oracle: Debt (USD)',
          desc: 'v4.7.3',
          purpose: CEO_DEBT_PURPOSE,
          maxFilterBlocks: 10_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x41F14ed7e7E8034a5EB4EC72BdE3C94F91ECfa10': {
          hide: v4FeedsHidden,
          name: 'Median Filtered Daily Average Oracle (USD)',
          desc: 'v4.7.2, GM-TWAP, 5x 24-hour periods median filtered',
          purpose: MEDIAN_DAO_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x029bebD699865f0623fbf475715Fb2aFEAc58027': {
          name: 'Historical Daily Average Oracle (USD)',
          desc: 'v4.7.2, GM-TWAP, 24-hour-period, 4x granularity',
          purpose: DAO_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x273215384b5281c5147EB2fd06688ED4cFfBD486': {
          hide: true,
          name: 'Daily Average Oracle (USD)',
          desc: 'v4.7.2, GM-TWAP, 24-hour-period, 4x granularity',
          purpose: DAO_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0xc8c89668Aab4052Fe18B86827A07289023c2ab6d': {
          hide: true,
          name: 'Liquidation Oracle Accumulator (USD)',
          maxFilterBlocks: 5_000_000,
          desc: 'v4.7.2, GM-TWAP',
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x9EdaB5295260AC27c13564E4827b73408C132270': {
          name: 'Liquidation Oracle (USD)',
          desc: 'v4.7.2, median of Chainlink and Pyth, high availability',
          maxFilterBlocks: 5_000_000,
          purpose: LIQUIDATION_ORACLE_PURPOSE,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x8aC5f2E2960fb3d022cD45f5410201c5bFc95891': {
          hide: true,
          name: 'Chainlink Oracle View ETH/USD',
          desc: 'v4.7.2',
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x4e22Ea0CB77B5aE0085551EF0fC5026C82c07e1D': {
          hide: true,
          name: 'Pyth Oracle View ETH/USD',
          desc: 'v4.7.2',
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x637D98D08331Af95DF392CC035629e64987E9Ae3': {
          name: '7d Daily Volatility Oracle (USD)',
          type: 'volatility',
          desc: 'v4.7.3, 7-day historical price volatility of a 24h GM-TWAP, log returns',
          decimals: 6,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: volatility,
        },
        '0x9c13Af75dA9684E0927437598f8caFbeAfa25d86': {
          hide: true,
          name: 'Deprecated: Daily Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 24-hour period, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
            '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0xCc9bb35d5a120cE95fAD3f036ef91662c4E3A9C9': {
          hide: true,
          name: 'Deprecated: Median Filtered Daily Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 3x 24-hour periods median filtered, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
            '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0xF8eBAeDD4c4174d30E54Db50F5D73D1e8214919e': {
          hide: true,
          name: 'Deprecated: 30d Daily Volatility Oracle (WETH)',
          type: 'volatility',
          desc: 'v4.0.0, 30-day historical price volatility of a 24h GM-TWAP, log returns, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 6,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: [
            '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
            '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
          ],
          dataPoints: volatility,
        },
        '0xC22FBc6782b635f96A453C34007c4b68236Dbf51': {
          hide: true,
          name: 'Deprecated: 7d Daily Volatility Oracle (WETH)',
          type: 'volatility',
          desc: 'v4.0.0, 7-day historical price volatility of a 24h GM-TWAP, log returns, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 6,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: [
            '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
            '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
          ],
          dataPoints: volatility,
        },
        '0x5aaBc28d974D27a67E536721b9c9C9C5d044e915': {
          hide: true,
          name: 'Deprecated: Thirty Minute Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 30-minute period, offchain price anchoring',
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 86400, // in seconds, 1 day
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
            '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0xbC1709f6E70D550d6Db5B17ae17522536e14bF15': {
          hide: v4FeedsHidden,
          name: 'Compound III USDC Interest Rate Oracle',
          desc: 'v4.0.0, GM-TWAP, 7-day period',
          maxFilterBlocks: 1_000_000_000,
          decimals: 16,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            iconOverlay: 'linear-gradient(rgb(67, 160, 71), rgb(56, 142, 60))',
          },
          updating: {
            price: {
              bips: 200, // 2%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: ['0x0000000000000000000000000000000000000010'],
          dataPoints: interestRate,
        },
        '0x73b870B838b039e560a6f15982D36E5eEa0914E9': {
          hide: v4FeedsHidden,
          name: 'Aave v3 USDC Interest Rate Oracle',
          desc: 'v4.0.0, GM-TWAP, 7-day period',
          maxFilterBlocks: 1_000_000_000,
          decimals: 16,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            iconOverlay:
              'linear-gradient(248.86deg, rgb(182, 80, 158) 10.51%, rgb(46, 186, 198) 23.41%)',
          },
          updating: {
            price: {
              bips: 200, // 2%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: ['0x0000000000000000000000000000000000000010'],
          dataPoints: interestRate,
        },
      },
      updaters: {
        // USDC/WETH dedicated updaters
        '0x0D47e283E4c999310Ae18c26f44Ac04E56076840': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.dex.primary,
        },
        '0x126611100f6E56d8790BA862AE4d245e444c84D4': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.dex.secondary,
        },
        '0x4339212f9b9C088b307295DC681F78BE3385d22F': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.dex.tertiary,
        },
        '0x55433d6A8074E9765b02343E269Abf72C7a19c61': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.dex.quaternary,
        },
        // WBTC/WETH dedicated updaters
        '0xaBef0e8222a926C169E1AAed88501B8706587b5d': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.dex.primary,
        },
        '0xfc4403b80919D93D02C17B9C86350BEc52742d2D': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.dex.secondary,
        },
        '0xaFE96Fb63d893E8CbCA78577e9906B2B23605a95': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.dex.tertiary,
        },
        '0xD3196704ffcF811a20D8CC66F9c61a4D2E73DD00': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.dex.quaternary,
        },
        // Compound III USDC interest rate dedicated updaters
        '0xb4D87040dCd801D587a32e5652B09357cCdFbeA0': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Interest Rate Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.lending.primary,
        },
        '0x7C99449fF4d45C90971a3268ae2d5E4Ff7b17Ad6': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Interest Rate Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.lending.secondary,
        },
        '0x2BF87C38D94bFaFAA2eA1a6E3585721ef19d1736': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Interest Rate Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.lending.tertiary,
        },
        '0xdf4c193d3Ef528A0799cEA18575566f7786a05CB': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Interest Rate Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.lending.quaternary,
        },
        // Aave v3 USDC interest rate dedicated updaters
        '0x52d2291995A14FcB735699196725B7cea18a95cB': {
          batchId: '3',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.lending.primary,
        },
        '0x0F7DFe3D38b7B1a4081F59B53a8D26ec34c031AF': {
          batchId: '3',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.lending.secondary,
        },
        '0x17976C3743F5575b718f0ae9E2BaA91C21CF1841': {
          batchId: '3',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.lending.tertiary,
        },
        '0x6AAd2C2910E831F314502a0d79fb441daA7f402A': {
          batchId: '3',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.arbitrumOne.lending.quaternary,
        },
        // Oku Trade dedicated updaters (batch 4)
        '0x0932094b49f69ee3E1386AC6866c49b15994fA34': {
          disabled: true,
          batchId: '4',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 20M gas limit and 0.125 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.025'),
        },
        '0xe90c12861711Bc3882F0f716A5941fC4a0D2EF95': {
          disabled: true,
          batchId: '4',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 20M gas limit and 0.125 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.025'),
        },
        '0x2a4484936Ae6153Cf19ddCa4db6EC85B4a750d49': {
          disabled: true,
          batchId: '4',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 20M gas limit and 0.125 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.025'),
        },
        '0x59dd181aD9051b45a210963916D6eb6657a667B8': {
          disabled: true,
          batchId: '4',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 20M gas limit and 0.125 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.025'),
        },
      },
      strategies: {
        validation: {
          [ethers.constants.AddressZero]: {
            name: 'None',
            desc: 'No (additional) validation is performed.',
          },
        },
        aggregation: {
          '0xC7E8e1daB09e7E29cda87C32DBDf34b8E2859003': {
            name: 'Median',
            desc: 'Computes the median %PRICE_SLOT%, sum of %TOKEN_LIQUIDITY_SLOT%, and sum of %QUOTE_TOKEN_LIQUIDITY_SLOT%.',
          },
          '0xC2273620B48BBbC38D193B7aaa05A9594668c0D6': {
            name: 'Minimum',
            desc: 'Computes the minimum %PRICE_SLOT%, sum of %TOKEN_LIQUIDITY_SLOT%, and sum of %QUOTE_TOKEN_LIQUIDITY_SLOT%.',
          },
          '0xc5DFF7Ce4A8D6B98c6eb70ACa1C5527d8147e539': {
            name: 'Maximum',
            desc: 'Computes the maximum %PRICE_SLOT%, sum of %TOKEN_LIQUIDITY_SLOT%, and sum of %QUOTE_TOKEN_LIQUIDITY_SLOT%.',
          },
        },
      },
    },
    avalanche: {
      name: 'Avalanche',
      chainId: Avalanche.chainId,
      defaultTopUpAmount: ethers.utils.parseEther('250.0'),
      defaultLowBalanceWarning: ethers.utils.parseEther('100.0'),
      defaultLowBalanceDanger: ethers.utils.parseEther('20'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {
        '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664': {
          name: 'USD Coin',
          symbol: 'USDC.e',
          decimals: 6,
          icon: 'usdc',
        },
        '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab': {
          name: 'Wrapped Ethereum',
          symbol: 'WETH.e',
          decimals: 18,
          icon: 'eth',
        },
        '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7': {
          name: 'Wrapped AVAX',
          symbol: 'WAVAX',
          decimals: 18,
          icon: 'avax',
        },
      },
      oracles: {},
      updaters: {},
    },
    bsc: {
      name: 'BNB Smart Chain',
      chainId: BSC.chainId,
      maxFilterBlocks: (30 * 24 * 60 * 60) / 3, // 30 days
      defaultTopUpAmount: ethers.utils.parseEther('0.1'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.03'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.01'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {
        '0xe9e7cea3dedca5984780bafc599bd69add087d56': {
          name: 'Binance USD',
          symbol: 'BUSD',
          decimals: 18,
          icon: 'busd',
        },
        '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': {
          name: 'Wrapped BNB',
          symbol: 'WBNB',
          decimals: 18,
          icon: 'bnb',
        },
        '0xfb6115445Bff7b52FeB98650C87f44907E58f802': {
          name: 'Binance-Peg Aave Token',
          symbol: 'AAVE',
          decimals: 18,
          icon: 'aave',
        },
        '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47': {
          name: 'Binance-Peg Cardano Token',
          symbol: 'ADA',
          decimals: 18,
          icon: 'ada',
        },
        '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf': {
          name: 'Binance-Peg Bitcoin Cash Token',
          symbol: 'BCH',
          decimals: 18,
          icon: 'bch',
        },
        '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B': {
          name: 'Binance Beacon ETH',
          symbol: 'BETH',
          decimals: 18,
          icon: 'binance-beth',
        },
        '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c': {
          name: 'Binace-Peg BTCB Token',
          symbol: 'BTCB',
          decimals: 18,
          icon: 'btc',
        },
        '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82': {
          name: 'PancakeSwap Token',
          symbol: 'CAKE',
          decimals: 18,
          icon: 'cake',
        },
        '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3': {
          name: 'Binance-Peg Dai Token',
          symbol: 'DAI',
          decimals: 18,
          icon: 'dai',
        },
        '0xbA2aE424d960c26247Dd6c32edC70B295c744C43': {
          name: 'Binance-Peg Dogecoin Token',
          symbol: 'DOGE',
          decimals: 18,
          icon: 'doge',
        },
        '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402': {
          name: 'Binance-Peg Polkadot Token',
          symbol: 'DOT',
          decimals: 18,
          icon: 'dot',
        },
        '0x2170Ed0880ac9A755fd29B2688956BD959F933F8': {
          name: 'Binance-Peg Ethereum Token',
          symbol: 'ETH',
          decimals: 18,
          icon: 'eth',
        },
        '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409': {
          name: 'First Digital USD',
          symbol: 'FDUSD',
          decimals: 18,
          icon: 'fdusd',
        },
        '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153': {
          name: 'Binance-Peg Filecoin',
          symbol: 'FIL',
          decimals: 18,
          icon: 'fil',
        },
        '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD': {
          name: 'Binance-Peg ChainLink Token',
          symbol: 'LINK',
          decimals: 18,
          icon: 'link',
        },
        '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94': {
          name: 'Binance-Peg Litecoin Token',
          symbol: 'LTC',
          decimals: 18,
          icon: 'ltc',
        },
        '0x156ab3346823B651294766e23e6Cf87254d68962': {
          name: 'LUNA',
          symbol: 'LUNA',
          decimals: 18,
          icon: 'luna',
        },
        '0xCC42724C6683B7E57334c4E856f4c9965ED682bD': {
          name: 'Matic Token',
          symbol: 'MATIC',
          decimals: 18,
          icon: 'matic',
        },
        '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A': {
          name: 'Swipe',
          symbol: 'SXP',
          decimals: 18,
          icon: 'sxp',
        },
        '0xCE7de646e7208a4Ef112cb6ed5038FA6cC6b12e3': {
          name: 'TRON',
          symbol: 'TRX',
          decimals: 18,
          icon: 'trx',
        },
        '0x40af3827F39D0EAcBF4A168f8D4ee67c121D11c9': {
          name: 'TrueUSD',
          symbol: 'TUSD',
          decimals: 18,
          icon: 'tusd',
        },
        '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1': {
          name: 'Binance-Peg Uniswap',
          symbol: 'UNI',
          decimals: 18,
          icon: 'uni',
        },
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': {
          name: 'Binance-Peg USD Coin',
          symbol: 'USDC',
          decimals: 18,
          icon: 'usdc',
        },
        '0x55d398326f99059fF775485246999027B3197955': {
          name: 'Tether USD',
          symbol: 'USDT',
          decimals: 18,
          icon: 'usdt',
        },
        '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7': {
          name: 'VAI Stablecoin',
          symbol: 'VAI',
          decimals: 18,
          icon: 'vai',
        },
        '0x5F84ce30DC3cF7909101C69086c50De191895883': {
          name: 'Venus Reward Token',
          symbol: 'VRT',
          decimals: 18,
          icon: 'vrt',
        },
        '0xa2E3356610840701BDf5611a53974510Ae27E2e1': {
          name: 'Wrapped Binance Beacon ETH',
          symbol: 'WBETH',
          decimals: 18,
          icon: 'binance-wbeth',
        },
        '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE': {
          name: 'Binance-Peg XRP Token',
          symbol: 'XRP',
          decimals: 18,
          icon: 'xrp',
        },
        '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63': {
          name: 'Venus',
          symbol: 'XVS',
          decimals: 18,
          icon: 'xvs',
        },
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18,
          icon: 'bnb',
        },
      },
      oracles: {
        '0xCFCdc27Cedd46550dA98118a21424B22ebC32A69': {
          hide: false,
          name: 'Venus Core Total Supply & Borrow Oracle',
          desc: 'v4.6.1, GM-TWA, 24-hour period, 2x granularity',
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 100, // 1%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', // BTCB
            '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', // BNB
            '0x55d398326f99059fF775485246999027B3197955', // USDT
            '0xfb6115445Bff7b52FeB98650C87f44907E58f802', // AAVE
            '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47', // ADA
            '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf', // BCH
            '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B', // BETH
            '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', // CAKE
            '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', // DAI
            '0xbA2aE424d960c26247Dd6c32edC70B295c744C43', // DOGE
            '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402', // DOT
            '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', // ETH
            '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409', // FDUSD
            '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153', // FIL
            '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD', // LINK
            '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94', // LTC
            '0xCC42724C6683B7E57334c4E856f4c9965ED682bD', // MATIC
            '0xCE7de646e7208a4Ef112cb6ed5038FA6cC6b12e3', // TRX
            '0x40af3827F39D0EAcBF4A168f8D4ee67c121D11c9', // TUSD
            '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1', // UNI
            '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', // USDC
            '0xa2E3356610840701BDf5611a53974510Ae27E2e1', // WBETH
            '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE', // XRP
            '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63', // XVS
          ],
          dataPoints: supplyAndBorrow,
        },
      },
      updaters: {
        // Oku Trade dedicated updaters (batch 0)
        '0xDEC04E2D8C489Ff5Dc787e520C1789b0C62ad9Ab': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 5.8 gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        '0x8003f1f4C002BD50a138E9AF7f111bF424d536f8': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 5.8 gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        '0xB52dFBbB0178c431b797CA4A3b39a9D95A9bfB07': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 5.8 gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        '0x51707f1406f552937455Ce090616F158A270F604': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 5.8 gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        // Venus dedicated updaters (batch 1)
        '0x1B9B6e7694002E5a13516c01B11A5eD0a31D060C': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Venus Primary Updater',
        },
        '0xDbEb1457BC1b4B2dcA66850825b0cC797Fdc1d1b': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Venus Secondary Updater',
        },
        '0x278712ec1E6A23aa2e136d9195e6b73318DE8734': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Venus Tertiary Updater',
        },
        '0x1831D028D180B1CaFE76C4F0C1a402983B7419Ed': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Venus Quaternary Updater',
        },
      },
    },
    optimism: {
      name: 'Optimism',
      chainId: Optimism.chainId,
      maxFilterBlocks: Math.ceil((3 * 30 * 24 * 60 * 60) / 2), // 3 months
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {
        '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1': {
          name: 'Dai Stablecoin',
          symbol: 'DAI',
          decimals: 18,
          icon: 'dai',
        },
        '0x4200000000000000000000000000000000000006': {
          name: 'Wrapped Ethereum',
          symbol: 'WETH',
          decimals: 18,
          icon: 'eth',
        },
        '0x7f5c764cbc14f9669b88837ca1490cca17c31607': {
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          icon: 'usdc',
        },
        '0x68f180fcce6836688e9084f035309e29bf0a2095': {
          name: 'Wrapped BTC',
          symbol: 'WBTC',
          decimals: 8,
          icon: 'wbtc',
        },
        '0x4200000000000000000000000000000000000042': {
          name: 'Optimism',
          symbol: 'OP',
          decimals: 18,
          icon: 'op',
        },
        '0x0000000000000000000000000000000000000010': {
          name: 'Deposit Interest Rate',
          symbol: '',
          decimals: 18,
          icon: 'fa-piggy-bank',
        },
        '0x0000000000000000000000000000000000000011': {
          name: 'Borrow Interest Rate - Variable',
          symbol: '',
          decimals: 18,
          icon: '',
        },
        '0x0000000000000000000000000000000000000012': {
          name: 'Borrow Interest Rate - Stable',
          symbol: '',
          decimals: 18,
          icon: '',
        },
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
          icon: 'eth',
        },
        '0xB0C4Dc7e937403ABb591860Dd589f350BE916019': {
          name: 'US Dollar',
          symbol: 'USD',
          decimals: 8,
          icon: 'usd',
        },
      },
      oracles: {
        '0x0045Bb2a3e89a2A0a58d533a977E0B8C4277F779': {
          name: 'Credit Extension Oracle: Collateral (USD)',
          desc: 'v4.7.3',
          purpose: CEO_COLLATERAL_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x25e0932cbAE0dC0Fe8895f46d5c30167DD42aFe2': {
          name: 'Credit Extension Oracle: Debt (USD)',
          desc: 'v4.7.3',
          purpose: CEO_DEBT_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x4Ace7Ed2941774FE9Ec58e0CeD53F91fCFe7e4cc': {
          hide: v4FeedsHidden,
          name: 'Median Filtered Daily Average Oracle (USD)',
          desc: 'v4.7.2, GM-TWAP, 5x 24-hour periods median filtered',
          purpose: MEDIAN_DAO_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0xaf5D43A1f971e1879996b536eD6aB92E83Fdeca1': {
          name: 'Historical Daily Average Oracle (USD)',
          desc: 'v4.7.2, GM-TWAP, 24-hour-period, 4x granularity',
          purpose: DAO_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x3060759F0D0BF60c373f9057BB1269c28Bd5Bb66': {
          hide: true,
          name: 'Daily Average Oracle (USD)',
          desc: 'v4.7.2, GM-TWAP, 24-hour-period, 4x granularity',
          purpose: DAO_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x94668980C90161734169F01e7df5b5E484D29aCe': {
          hide: true,
          name: 'Liquidation Oracle Accumulator (USD)',
          maxFilterBlocks: 1_000_000,
          desc: 'v4.7.2, GM-TWAP',
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x8950365aAAe83aa8CFAD779457423ac2D8FF8Ce0': {
          name: 'Liquidation Oracle (USD)',
          desc: 'v4.7.2, median of Chainlink and Pyth, high availability',
          maxFilterBlocks: 1_000_000,
          purpose: LIQUIDATION_ORACLE_PURPOSE,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x00922ad039612B8b2DD9a8b10e6a834cec74B9DC': {
          hide: true,
          name: 'Chainlink Oracle View ETH/USD',
          desc: 'v4.7.2',
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0xc68a2bBfE00786B2710FfDe68e03Fa80654280Ce': {
          hide: true,
          name: 'Pyth Oracle View ETH/USD',
          desc: 'v4.7.2',
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x24e84592617834f04BeFAbcBb0e71637D274A278': {
          name: '7d Daily Volatility Oracle (USD)',
          type: 'volatility',
          desc: 'v4.7.3, 7-day historical price volatility of a 24h GM-TWAP, log returns',
          decimals: 6,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: volatility,
        },
        '0xFA84f31f3187b921BAB4D3E3206d0b0DE7A3D94b': {
          hide: true,
          name: 'Deprecated: Daily Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 24-hour period, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
            '0x68f180fcce6836688e9084f035309e29bf0a2095',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0x6bb5e2F80F929ab467335F9a8ed10585619e24F5': {
          hide: true,
          name: 'Deprecated: Median Filtered Daily Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 3x 24-hour periods median filtered, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
            '0x68f180fcce6836688e9084f035309e29bf0a2095',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0x7cdc2c52a070762Eb7000065Dfe40c828ACC96DD': {
          hide: true,
          name: 'Deprecated: 30d Daily Volatility Oracle (WETH)',
          type: 'volatility',
          desc: 'v4.0.0, 30-day historical price volatility of a 24h GM-TWAP, log returns, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 6,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: [
            '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
            '0x68f180fcce6836688e9084f035309e29bf0a2095',
          ],
          dataPoints: volatility,
        },
        '0xCC35CCAAb98A31fc81C26Ccc53dA7419DE9869B7': {
          hide: true,
          name: 'Deprecated: 7d Daily Volatility Oracle (WETH)',
          type: 'volatility',
          desc: 'v4.0.0, 7-day historical price volatility of a 24h GM-TWAP, log returns, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 6,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: [
            '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
            '0x68f180fcce6836688e9084f035309e29bf0a2095',
          ],
          dataPoints: volatility,
        },
        '0x449D131f2Ae53a337FE5A21b3D749E603ce6454c': {
          hide: true,
          name: 'Deprecated: Thirty Minute Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 30-minute period, offchain price anchoring',
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
            '0x68f180fcce6836688e9084f035309e29bf0a2095',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0x68AA498367380E2025e8E8782c50EFF7766db052': {
          hide: v4FeedsHidden,
          name: 'Aave v3 USDC Interest Rate Oracle',
          desc: 'v4.0.0, GM-TWAP, 7-day period',
          maxFilterBlocks: 1_000_000_000,
          decimals: 16,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            iconOverlay:
              'linear-gradient(248.86deg, rgb(182, 80, 158) 10.51%, rgb(46, 186, 198) 23.41%)',
          },
          updating: {
            price: {
              bips: 200, // 2%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: ['0x0000000000000000000000000000000000000010'],
          dataPoints: interestRate,
        },
      },
      updaters: {
        // USDC/WETH dedicated updaters
        '0x9b39d511dca866bfb8d8e6132fc0ef02d26ce8d1': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.dex.primary,
        },
        '0x97944B231F42D9C0243e774c024fA89BDD31Ee07': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.dex.secondary,
        },
        '0x3Fc02B4B71d66b8cB7809E2Df931324B33b45e1c': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.dex.tertiary,
        },
        '0xfcd228553AB6b84f67BbA6D08f4b2775FEeC274e': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.dex.quaternary,
        },
        // WBTC/WETH dedicated updaters
        '0xfa2fF9272819a050906e8EbD78231C9D11A741E6': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.dex.primary,
        },
        '0xEB464B28aDc6FE23448c860668f3548beA58Bdc4': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.dex.secondary,
        },
        '0x6b399dC99CaA7170BFCa95d21385f91f4987Ce78': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.dex.tertiary,
        },
        '0xAF39efE9aD7BE6389af2Ba58131d138eb44875D5': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.dex.quaternary,
        },
        // Aave v3 USDC interest rate dedicated updaters
        '0x692EE78CF1FBEe67E61bC2686538Ad1603F2cA11': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.lending.primary,
        },
        '0x6F0344639E9D2a54d701d1b59a97A55f43590Af7': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.lending.secondary,
        },
        '0xCdE843FC2aA9988b130D46FFa95deB1e4BF1C5b3': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.lending.tertiary,
        },
        '0xaF1e34B65042dD0f575F8B1E49C204E48AE5BC49': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.optimism.lending.quaternary,
        },
        // Oku Trade dedicated updaters (batch 3)
        '0xB9ea420A04F7dFA67AC6Ee8812610C5A2330707B': {
          disabled: true,
          batchId: '3',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 0.01 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        '0x72196b9D6d1078B9054018f7cBB9a7E60d7a2ACd': {
          disabled: true,
          batchId: '3',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 0.01 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        '0x178B8D106c16eA9Cf44f29FCAcCEB19Db5983c26': {
          disabled: true,
          batchId: '3',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 0.01 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        '0x098959dc942AC4C11876578Ae5378641c89cCDae': {
          disabled: true,
          batchId: '3',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 0.01 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
      },
      strategies: {
        validation: {
          [ethers.constants.AddressZero]: {
            name: 'None',
            desc: 'No (additional) validation is performed.',
          },
        },
        aggregation: {
          '0x2a0755Ca1EbcB9c37C883c09DE5202b3cc7b7470': {
            name: 'Median',
            desc: 'Computes the median %PRICE_SLOT%, sum of %TOKEN_LIQUIDITY_SLOT%, and sum of %QUOTE_TOKEN_LIQUIDITY_SLOT%.',
          },
          '0x248Ab376F7dB8B06e7c9EA1dE3E746d47Ee489c3': {
            name: 'Minimum',
            desc: 'Computes the minimum %PRICE_SLOT%, sum of %TOKEN_LIQUIDITY_SLOT%, and sum of %QUOTE_TOKEN_LIQUIDITY_SLOT%.',
          },
          '0x10E514cEF50424306eDD391b2d4cB016930E1cc2': {
            name: 'Maximum',
            desc: 'Computes the maximum %PRICE_SLOT%, sum of %TOKEN_LIQUIDITY_SLOT%, and sum of %QUOTE_TOKEN_LIQUIDITY_SLOT%.',
          },
        },
      },
    },
    polygonZkEVM: {
      name: 'Polygon ZkEVM',
      chainId: PolygonZkEVM.chainId,
      maxFilterBlocks: 10000,
      defaultTopUpAmount: ethers.utils.parseEther('1.0'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.2'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.1'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {
        '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035': {
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          icon: 'usdc',
        },
        '0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9': {
          name: 'Wrapped Ether',
          symbol: 'WETH',
          decimals: 18,
          icon: 'eth',
        },
        '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1': {
          name: 'Wrapped Bitcoin',
          symbol: 'WBTC',
          decimals: 8,
          icon: 'wbtc',
        },
        '0xa2036f0538221a77A3937F1379699f44945018d0': {
          name: 'Matic',
          symbol: 'MATIC',
          decimals: 18,
          icon: 'matic',
        },
      },
      oracles: {
        '0x06F8968411067d9122B6d4Ec7Bd3d69A220D504a': {
          hide: v4FeedsHidden,
          name: 'Daily Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 24-hour period, offchain price anchoring',
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035',
            '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0xd158c6d1fBefFC16A3C2F60aF4D293E70cc3942A': {
          hide: v4FeedsHidden,
          name: 'Median Filtered Daily Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 3x 24-hour periods median filtered, offchain price anchoring',
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035',
            '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0x0315a3e20e772b6D444B7B45e743e5a0CF80D7A2': {
          hide: v4FeedsHidden,
          name: '30d Daily Volatility Oracle (WETH)',
          type: 'volatility',
          desc: 'v4.0.0, 30-day historical price volatility of a 24h GM-TWAP, log returns, offchain price anchoring',
          decimals: 6,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: [
            '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035',
            '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1',
          ],
          dataPoints: volatility,
        },
        '0xDe06FD597BDc822bB3F729B55639B46a91E0350a': {
          hide: v4FeedsHidden,
          name: '7d Daily Volatility Oracle (WETH)',
          type: 'volatility',
          desc: 'v4.0.0, 7-day historical price volatility of a 24h GM-TWAP, log returns, offchain price anchoring',
          decimals: 6,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: [
            '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035',
            '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1',
          ],
          dataPoints: volatility,
        },
        '0x76f54185815Cd1A4423adff558E90394650Ca0FC': {
          hide: v4FeedsHidden,
          name: 'Thirty Minute Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 30-minute period, offchain price anchoring',
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035',
            '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
      },
      updaters: {
        // USDC/WETH dedicated updaters
        '0x55b7B7C8D86275cA0dc8203c49DFdCe26bfa270c': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygonZkEVM.dex.primary,
        },
        '0xD54baa6ae80E9EC2270B564ed2CdD4061c237d75': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygonZkEVM.dex.secondary,
        },
        '0x0A39333C1F739f716af92EccDC5fC62c8a33cd18': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygonZkEVM.dex.tertiary,
        },
        '0x8dD1dDe7307c2C706AE1969D45bC7f3C5bD81FAd': {
          disabled: true,
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygonZkEVM.dex.quaternary,
        },
        // WBTC/WETH dedicated updaters
        '0xcbC34D3321B676D9A4c78a618747b3dA095A6d27': {
          disabled: true,
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygonZkEVM.dex.primary,
        },
        '0x3f06b82e199c41dFDF0e114b683B67240B47db9E': {
          disabled: true,
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygonZkEVM.dex.secondary,
        },
        '0xf75b33f3DFA13CaDCCD48b1A61f363b3a77683f4': {
          disabled: true,
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygonZkEVM.dex.tertiary,
        },
        '0xf0a4Dc8A3DC27A6cB9551a8CF11156bF03f87260': {
          disabled: true,
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygonZkEVM.dex.quaternary,
        },
        // Oku Trade dedicated updaters
        '0x5480cEe5752dAAc2C3B35BeD199864c6d8F4a779': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 3.05 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        '0x67C5b8D8aE885e49874B5418311d9e296eC08e1B': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 3.05 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        '0xE47177B3f6905DB01b43700B3d93e11B19014aE6': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 3.05 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
        '0xeE029C655cB1d8F2665885eCC57F2C03B670D489': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
          topUpAmount: ethers.utils.parseEther('0.1'),
          // Amounts based on 5M gas limit and 3.05 Gwei gas price
          lowBalanceWarning: ethers.utils.parseEther('0.05'),
          lowBalanceDanger: ethers.utils.parseEther('0.03'),
        },
      },
    },
    polygon: {
      name: 'Polygon',
      chainId: Polygon.chainId,
      maxFilterBlocks: 1500000,
      logsBatchSize: 1500000,
      defaultTopUpAmount: ethers.utils.parseEther('250.0'),
      defaultLowBalanceWarning: ethers.utils.parseEther('100.0'),
      defaultLowBalanceDanger: ethers.utils.parseEther('20.0'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {
        '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': {
          name: 'Bridged USD Coin',
          symbol: 'USDC',
          decimals: 6,
          icon: 'usdc',
        },
        '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359': {
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          icon: 'usdc',
        },
        '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619': {
          name: 'Wrapped Ethereum',
          symbol: 'WETH',
          decimals: 18,
          icon: 'eth',
        },
        '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270': {
          name: 'Wrapped Matic',
          symbol: 'WMATIC',
          decimals: 18,
          icon: 'matic',
        },
        '0xE6469Ba6D2fD6130788E0eA9C0a0515900563b59': {
          name: 'TerraUSD',
          symbol: 'UST',
          decimals: 6,
          icon: 'ust',
        },
        '0xc2132d05d31c914a87c6611c10748aeb04b58e8f': {
          name: 'Tether USD',
          symbol: 'USDT',
          decimals: 6,
          icon: 'usdt',
        },
        '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6': {
          name: 'Wrapped Bitcoin',
          symbol: 'WBTC',
          decimals: 8,
          icon: 'wbtc',
        },
        '0xd6df932a45c0f255f85145f286ea0b292b21c90b': {
          name: 'Aave',
          symbol: 'AAVE',
          decimals: 18,
          icon: 'aave',
        },
        '0xbbba073c31bf03b8acf7c28ef0738decf3695683': {
          name: 'Sand',
          symbol: 'SAND',
          decimals: 18,
          icon: 'sand',
        },
        '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4': {
          name: 'Lido Staked Matic',
          symbol: 'stMATIC',
          decimals: 18,
          icon: 'stmatic',
        },
        '0x831753dd7087cac61ab5644b308642cc1c33dc13': {
          name: 'Quickswap',
          symbol: 'QUICK',
          decimals: 18,
          icon: 'quick',
        },
        '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063': {
          name: 'Dai Stablecoin',
          symbol: 'DAI',
          decimals: 18,
          icon: 'dai',
        },
        '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89': {
          name: 'Frax',
          symbol: 'FRAX',
          decimals: 18,
          icon: 'frax',
        },
        '0x172370d5cd63279efa6d502dab29171933a610af': {
          name: 'Curve DAO Token',
          symbol: 'CRV',
          decimals: 18,
          icon: 'crv',
        },
        '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39': {
          name: 'Chainlink Token',
          symbol: 'LINK',
          decimals: 18,
          icon: 'link',
        },
        '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4': {
          name: 'Decentraland',
          symbol: 'MANA',
          decimals: 18,
          icon: 'mana',
        },
        '0x2e1ad108ff1d8c782fcbbb89aad783ac49586756': {
          name: 'TrueUSD',
          symbol: 'TUSD',
          decimals: 18,
          icon: 'tusd',
        },
        '0x5fe2b58c013d7601147dcdd68c143a77499f5531': {
          name: 'Graph Token',
          symbol: 'GRT',
          decimals: 18,
          icon: 'grt',
        },
        '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a': {
          name: 'SushiToken',
          symbol: 'SUSHI',
          decimals: 18,
          icon: 'sushi',
        },
        '0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c': {
          name: 'Compound',
          symbol: 'COMP',
          decimals: 18,
          icon: 'comp',
        },
        '0xb33eaad8d922b1083446dc23f610c2567fb5180f': {
          name: 'Uniswap',
          symbol: 'UNI',
          decimals: 18,
          icon: 'uni',
        },
        '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1': {
          name: 'miMATIC',
          symbol: 'MAI',
          decimals: 18,
          icon: 'mai',
        },
        '0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c': {
          name: 'Jarvis Synthetic Euro',
          symbol: 'jEUR',
          decimals: 18,
          icon: 'jeur',
        },
        '0xe0b52e49357fd4daf2c15e02058dce6bc0057db4': {
          name: 'agEUR',
          symbol: 'agEUR',
          decimals: 18,
          icon: 'ageur',
        },
        '0xe111178a87a3bff0c8d18decba5798827539ae99': {
          name: 'STASIS EURS Token',
          symbol: 'EURS',
          decimals: 2,
          icon: 'eurs',
        },
        '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3': {
          name: 'Balancer',
          symbol: 'BAL',
          decimals: 18,
          icon: 'bal',
        },
        '0x85955046df4668e1dd369d2de9f3aeb98dd2a369': {
          name: 'DefiPulse Index',
          symbol: 'DPI',
          decimals: 18,
          icon: 'dpi',
        },
        '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7': {
          name: 'Aavegotchi GHST Token',
          symbol: 'GHST',
          decimals: 18,
          icon: 'ghst',
        },
        '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6': {
          name: 'Liquid Staking Matic',
          symbol: 'MaticX',
          decimals: 18,
          icon: 'maticx',
        },
        '0x0000000000000000000000000000000000000001': {
          name: 'Fast Gas',
          symbol: '',
          decimals: 9,
          icon: 'fa-gas-pump',
        },
        '0x0000000000000000000000000000000000000010': {
          name: 'Deposit Interest Rate',
          symbol: '',
          decimals: 18,
          icon: 'fa-piggy-bank',
        },
        '0x0000000000000000000000000000000000000011': {
          name: 'Borrow Interest Rate - Variable',
          symbol: '',
          decimals: 18,
          icon: '',
        },
        '0x0000000000000000000000000000000000000012': {
          name: 'Borrow Interest Rate - Stable',
          symbol: '',
          decimals: 18,
          icon: '',
        },
        '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a': {
          name: 'SushiToken',
          symbol: 'SUSHI',
          decimals: 18,
          icon: 'sushi',
        },
        '0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944': {
          name: 'TL ALOC',
          symbol: 'tlALOC',
          decimals: 6,
          icon: 'tru',
        },
        '0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd': {
          name: 'Wrapped liquid staked Ether 2.0',
          symbol: 'wstETH',
          decimals: 18,
          icon: 'steth',
        },
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
          icon: 'eth',
        },
        '0x82a80078c6a9000A7bDc8D0FF00886227D7cE856': {
          name: 'US Dollar',
          symbol: 'USD',
          decimals: 8,
          icon: 'usd',
        },
      },
      oracles: {
        '0x3923FF62EE25D21b0b7C95B94a30e309C127FECF': {
          name: 'Credit Extension Oracle: Collateral (USD)',
          desc: 'v4.7.3',
          purpose: CEO_COLLATERAL_PURPOSE,
          maxFilterBlocks: 500_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x5EFb52b46585c053e93584A6504092EFf523dee7': {
          name: 'Credit Extension Oracle: Debt (USD)',
          desc: 'v4.7.3',
          purpose: CEO_DEBT_PURPOSE,
          maxFilterBlocks: 500_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0xe6D09cD4a7f59B1be72513E7F3BA425b81e00948': {
          hide: v4FeedsHidden,
          name: 'Median Filtered Daily Average Oracle (USD)',
          desc: 'v4.7.2, GM-TWAP, 5x 24-hour periods median filtered',
          purpose: MEDIAN_DAO_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x3BDbF22B91F32f4c0058074e58DB028723566722': {
          name: 'Historical Daily Average Oracle (USD)',
          desc: 'v4.7.2, GM-TWAP, 24-hour-period, 4x granularity',
          purpose: DAO_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0xd157F6bcb806446aBf924C633DcdF8899D1EACA4': {
          hide: true,
          name: 'Daily Average Oracle (USD)',
          desc: 'v4.7.2, GM-TWAP, 24-hour-period, 4x granularity',
          purpose: DAO_PURPOSE,
          maxFilterBlocks: 1_000_000_000,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x3Eea92BD57FD5F94A8c41488fB6005B1a9a79c6d': {
          hide: true,
          name: 'Liquidation Oracle Accumulator (USD)',
          maxFilterBlocks: 500_000,
          desc: 'v4.7.2, GM-TWAP',
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x0D32b0B3D75Ad5209BAA2DC8E0E7a2cF698a5551': {
          name: 'Liquidation Oracle (USD)',
          desc: 'v4.7.2, median of Chainlink and Pyth, high availability',
          maxFilterBlocks: 500_000,
          purpose: LIQUIDATION_ORACLE_PURPOSE,
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 24 * 60 * 60, // in seconds, 24 hours
            },
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0xeb0FF74b91F46b1d5BB4402736D6DFEfc1B0f532': {
          hide: true,
          name: 'Chainlink Oracle View ETH/USD',
          desc: 'v4.7.2',
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x9b5b125DaE8e21EB0207430C229F11198bD116fc': {
          hide: true,
          name: 'Pyth Oracle View ETH/USD',
          desc: 'v4.7.2',
          decimals: 8,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            ratePrefix: '$',
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: priceDataPoints,
        },
        '0x7bF08a05D5Fc8dA3082b990b80A0C6f636802B94': {
          name: '7d Daily Volatility Oracle (USD)',
          type: 'volatility',
          desc: 'v4.7.3, 7-day historical price volatility of a 24h GM-TWAP, log returns',
          decimals: 6,
          quoteUnit: {
            symbol: 'USD',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: ['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
          dataPoints: volatility,
        },
        '0x4D12fCf54d975DcC849FF4A2330B34ad93D26BeB': {
          hide: true,
          name: 'Deprecated: Credit Extension Oracle: Collateral (WETH)',
          desc: 'Version: v4.2.0, Aggregation strategy: Minimum, Liquidation oracle: Chainlink, Damping oracle: Adrastia Median Filtered Daily Average Oracle',
          maxFilterBlocks: 1_000_000_000,
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 86400, // in seconds, 24 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 86400, // in seconds, 24 hours
            },
          },
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0x3710c3387D26a81EC26B7C71888333A5C6408A0d': {
          hide: true,
          name: 'Deprecated: Credit Extension Oracle: Debt (WETH)',
          desc: 'Version: v4.2.0, Aggregation strategy: Maximum, Liquidation oracle: Chainlink, Damping oracle: Adrastia Median Filtered Daily Average Oracle',
          maxFilterBlocks: 1_000_000_000,
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 86400, // in seconds, 24 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 86400, // in seconds, 24 hours
            },
          },
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0xAb46C8A1876CC3656326Bb2B3616a9B4E891007B': {
          hide: true,
          name: 'Deprecated: Daily Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 24-hour period, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0x0a3E7369699eeBDbAfA11fE574C8619E71e9e271': {
          hide: true,
          name: 'Deprecated: Median Filtered Daily Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 3x 24-hour periods median filtered, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0x6660842162Dc0D414453a6b976Ea6Dbe45b97506': {
          hide: true,
          name: 'Deprecated: 30d Daily Volatility Oracle (WETH)',
          type: 'volatility',
          desc: 'v4.0.0, 30-day historical price volatility of a 24h GM-TWAP, log returns, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 6,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          ],
          dataPoints: volatility,
        },
        '0x00428b3Ab83AC7f3C953f35cF3f6dA5588613eB7': {
          hide: true,
          name: 'Deprecated: 7d Daily Volatility Oracle (WETH)',
          type: 'volatility',
          desc: 'v4.0.0, 7-day historical price volatility of a 24h GM-TWAP, log returns, offchain price anchoring',
          maxFilterBlocks: 1_000_000_000,
          decimals: 6,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Price Volatility Chart',
          },
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          ],
          dataPoints: volatility,
        },
        '0xb349D958b9E35084814B7AAb162bf3A8079d46c2': {
          hide: true,
          name: 'Deprecated: Thirty Minute Average Oracle (WETH)',
          desc: 'v4.0.0, GM-TWAP, HM-TWAL, 30-minute period, offchain price anchoring',
          decimals: 18,
          quoteUnit: {
            symbol: 'WETH',
          },
          display: {
            ratePrefix: 'Ξ',
          },
          updating: {
            price: {
              bips: 50, // 0.5%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 28800, // in seconds, 8 hours
            },
          },
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          ],
          dataPoints: dexPriceAndLiquidity,
        },
        '0xbcdEf2ab92B23De79f38111f4C721B3A34320b90': {
          hide: true,
          name: 'Deprecated: Current Rate Oracle (WMATIC)',
          desc: 'v4.0.0, current price and liquidity, offchain price anchoring',
          decimals: 18,
          quoteUnit: {
            symbol: 'WMATIC',
          },
          display: {
            rateSuffix: ' WMATIC',
          },
          updating: {
            price: {
              bips: 10, // 0.1%
              heartbeat: 14400, // in seconds, 4 hours
            },
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 14400, // in seconds, 4 hours
            },
          },
          tokens: ['0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4'],
          dataPoints: dexPriceAndLiquidity,
        },
        '0xb8fa3e600a0a37bb35fb2e68f6a288f65949df54': {
          hide: true,
          name: 'Deprecated: Fast Gas Oracle',
          desc: 'v4.0.0, AM-TWAP, 15-minute period, powered by Polygonscan',
          decimals: 9,
          quoteUnit: {
            symbol: 'Gwei',
          },
          display: {
            rateSuffix: ' Gwei',
            iconOverlay: 'linear-gradient(rgb(126, 87, 194), rgb(103, 58, 183))',
          },
          updating: {
            price: {
              bips: 2500, // 25%
              heartbeat: 900, // in seconds, 15 minutes
            },
          },
          tokens: ['0x0000000000000000000000000000000000000001'],
          dataPoints: gasPrice,
        },
        '0x4D5881b3DFA882e1b52B64C83d8Dc6c8F2CdF8DA': {
          hide: v4FeedsHidden,
          name: 'Compound III USDC Interest Rate Oracle',
          desc: 'v4.0.0, GM-TWAP, 7-day period',
          maxFilterBlocks: 1_000_000_000,
          decimals: 16,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            iconOverlay: 'linear-gradient(rgb(67, 160, 71), rgb(56, 142, 60))',
          },
          updating: {
            price: {
              bips: 200, // 2%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: ['0x0000000000000000000000000000000000000010'],
          dataPoints: interestRate,
        },
        '0x5ebB1ADA9Df2C39bA65748Cd545499dA1AA26CCf': {
          hide: v4FeedsHidden,
          name: 'Aave v3 USDC Interest Rate Oracle',
          desc: 'v4.0.0, GM-TWAP, 7-day period',
          maxFilterBlocks: 1_000_000_000,
          decimals: 16,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            iconOverlay:
              'linear-gradient(248.86deg, rgb(182, 80, 158) 10.51%, rgb(46, 186, 198) 23.41%)',
          },
          updating: {
            price: {
              bips: 200, // 2%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: ['0x0000000000000000000000000000000000000010'],
          dataPoints: interestRate,
        },
        '0x9dF856A7dF936a11924A794A3B18B37B8C1c5F93': {
          hide: v4FeedsHidden,
          name: 'Compound III USDC Total Supply & Borrow Oracle',
          desc: 'v4.1.0, GM-TWA, 24-hour period',
          maxFilterBlocks: 1_000_000_000,
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          ],
          dataPoints: supplyAndBorrow,
        },
        '0x97BB0d5ee1FFCe9340465c7D0B91F63DeF4bC929': {
          hide: true,
          name: 'Aave v3 Total Supply & Borrow Accumulator',
          desc: 'v4.1.0, GM-TWA, 24-hour period',
          maxFilterBlocks: 1_000_000_000,
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
            '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6',
            '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4',
            '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
            '0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd',
            '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
            '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
            '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
            '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
            '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
            '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3',
            '0x172370d5cd63279efa6d502dab29171933a610af',
            '0xe111178a87a3bff0c8d18decba5798827539ae99',
            '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
            '0xe0b52e49357fd4daf2c15e02058dce6bc0057db4',
            '0x85955046df4668e1dd369d2de9f3aeb98dd2a369',
          ],
          dataPoints: supplyAndBorrow,
        },
        '0xc0514a2A4eD8bA8cf3f9Bb71cC8d6e0cA0E5A4a7': {
          hide: v4FeedsHidden,
          name: 'Aave v3 Total Supply & Borrow Oracle',
          desc: 'v4.1.0, GM-TWA, 24-hour period',
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 1000, // 10%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
            '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6',
            '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4',
            '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
            '0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd',
            '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
            '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
            '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
            '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
            '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
            '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3',
            '0x172370d5cd63279efa6d502dab29171933a610af',
            '0xe111178a87a3bff0c8d18decba5798827539ae99',
            '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
            '0xe0b52e49357fd4daf2c15e02058dce6bc0057db4',
            '0x85955046df4668e1dd369d2de9f3aeb98dd2a369',
          ],
          dataPoints: supplyAndBorrow,
        },
        '0x0E3612fcD04688f115D06F76848F9D7629F3f019': {
          hide: true,
          name: 'TrueFi ALOC Utilization & Error Oracle (1)',
          desc: '',
          type: 'valueAndError',
          decimals: 8,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Utilization and Error Chart',
          },
          updating: {
            liquidity: {
              bips: 500, // 5%
              heartbeat: 8 * 60 * 60, // 8 hours
            },
          },
          tokens: ['0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944'],
          dataPoints: utilizationAndError,
        },
        '0x40E6FAa798aAb7B06426DeCA12d1A2e978CD3327': {
          hide: true,
          name: 'TrueFi ALOC Utilization & Error Oracle (2)',
          desc: '',
          type: 'valueAndError',
          decimals: 8,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Utilization and Error Chart',
            decimals: 6,
          },
          updating: {
            liquidity: {
              bips: 500, // 5%
              heartbeat: 8 * 60 * 60, // 8 hours
            },
          },
          tokens: ['0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944'],
          dataPoints: utilizationAndError,
        },
        '0x0478464cEb3e9B94aeDE6c916e8C997e09a2400a': {
          hide: false,
          name: 'TrueFi ALOC Utilization & Error Oracle (3)',
          desc: '',
          type: 'valueAndError',
          decimals: 8,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Utilization and Error Chart',
            decimals: 6,
          },
          updating: {
            liquidity: {
              bips: 500, // 5%
              heartbeat: 8 * 60 * 60, // 8 hours
            },
          },
          tokens: ['0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944'],
          dataPoints: utilizationAndError,
        },
        '0x8FAa64B3e98744e116981C2a10A6A31dEFB290dC': {
          hide: true,
          name: 'TrueFi ALOC Utilization & Error Accumulator (2)',
          desc: '',
          type: 'valueAndError',
          decimals: 8,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Utilization and Error Chart',
            decimals: 6,
          },
          updating: {
            liquidity: {
              bips: 500, // 5%
              heartbeat: 8 * 60 * 60, // 8 hours
            },
          },
          tokens: ['0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944'],
          dataPoints: utilizationAndError,
        },
        '0xcB122922fc3dC083e2329F4014D11A1d20BD19ee': {
          hide: false,
          name: 'TrueFi ALOC Utilization & Error Accumulator (3)',
          desc: '',
          type: 'valueAndError',
          decimals: 8,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Utilization and Error Chart',
            decimals: 6,
          },
          updating: {
            liquidity: {
              bips: 500, // 5%
              heartbeat: 8 * 60 * 60, // 8 hours
            },
          },
          tokens: ['0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944'],
          dataPoints: utilizationAndError,
        },
      },
      controllers: {
        '0xcD9B8Db8c0B097B043e8439684e0F3fB65b8b887': {
          name: 'Aave v3 Supply Cap Controller Demo',
          desc: '',
          type: 'capController',
          decimals: 0,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // USDC
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', // WBTC
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // WMATIC
          ],
          dataPoints: ratesSupplyCap,
          maxFilterBlocks: 15000000,
        },
        '0x6853Db03894c5197671111cc7D86f2280e7fcC8e': {
          hide: true,
          name: 'Aave v3 Supply Cap Computer Demo',
          desc: '',
          type: 'oracleMutationComputer',
          decimals: 0,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // USDC
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', // WBTC
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // WMATIC
          ],
          dataPoints: ratesSupplyCap,
        },
        '0x8B2B7321FC5C5EA2d185c6fb2Bf526c043166891': {
          name: 'Aave v3 Borrow Cap Controller Demo',
          desc: '',
          type: 'capController',
          decimals: 0,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // USDC
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', // WBTC
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // WMATIC
          ],
          dataPoints: ratesBorrowCap,
          maxFilterBlocks: 15000000,
        },
        '0x2d4506A825D031Af598463A67Da11BeC700b753a': {
          hide: true,
          name: 'Aave v3 Borrow Cap Computer Demo',
          desc: '',
          type: 'oracleMutationComputer',
          decimals: 0,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // USDC
            '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', // WBTC
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // WMATIC
          ],
          dataPoints: ratesBorrowCap,
          maxFilterBlocks: 15000000,
        },
        '0x258F6a00c813B52e8A5fc8E2F5A948b087F61699': {
          hide: true,
          name: 'TrueFi PID Controller Demo (1)',
          desc: '',
          type: 'pidController',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 2,
          },
          tokens: [
            '0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944', // TL ALOC
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0x166f63a17FEc415895303D8e4ae282e360CA4A01': {
          hide: true,
          name: 'TrueFi PID Controller Demo (2)',
          desc: '',
          type: 'pidController',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 2,
          },
          tokens: [
            '0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944', // TL ALOC
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0xcAB2517E19FDc87fAcb6a048A06A81aCB916f080': {
          hide: false,
          name: 'TrueFi Interest Rate Provider Demo',
          desc: 'Top-level interest rate provider with timelocking',
          type: 'historicalRatesComputer',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 2,
          },
          tokens: [
            '0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944', // TL ALOC
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0x4FA20AEC5658508651f0d4b474D0F48fde6187B6': {
          hide: false,
          name: 'TrueFi Interest Rate Controller Demo',
          desc: 'Mid-level interest rate controller',
          type: 'rateController',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 2,
          },
          tokens: [
            '0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944', // TL ALOC
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0x16C3d4eEDE7Ea533093738285eB726dD87E197ba': {
          hide: false,
          name: 'TrueFi Sloped Interest Rate Computer Demo',
          desc: 'Low-level utilization-based interest rate computer',
          type: 'slopedOracleMutationComputer',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 2,
          },
          tokens: [
            '0xF0f9b11FC04B3Ee2C45BEE625e6a01BFB2efb944', // TL ALOC
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
      },
      updaters: {
        // USDC/WETH dedicated updaters
        '0xc9f551a5f3f7e9d5c4d1a031e0f926aadb391c2d': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.primary,
        },
        '0x1da6d533e81f8d6590fb8a09d03de1820390f331': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.secondary,
        },
        '0xd4D6384B04b31260E49051212Aec5d01D6787E62': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.tertiary,
        },
        '0x0093252b2eD576cd5818C3AEEb5d00d6f13c195F': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'USDC/WETH Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.quaternary,
        },
        // WBTC/WETH dedicated updaters
        '0x93a83d37da075304e136b4d72df558c396f6d40b': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.primary,
        },
        '0xe30c71c0ed797ac7a0d33858bd5114b4df5c4064': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.secondary,
        },
        '0x7Ae60A49ef70A211eC11BF3380E5aEfCA7dCA4A7': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.tertiary,
        },
        '0xEc3C392b0cAae7eD7270b869f1b20A35fE682DcC': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WBTC/WETH Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.quaternary,
        },
        // WMATIC/WETH dedicated updaters
        '0x253EE59e957cC7412379eE2E091d247bE9c99daA': {
          batchId: '6',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WMATIC/WETH Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.primary,
        },
        '0x681baAB2545D106F3B8B87D403e221d717871dCe': {
          batchId: '6',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WMATIC/WETH Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.secondary,
        },
        '0x37c05e84c6384A007280b552c97E8e24F72744E7': {
          batchId: '6',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WMATIC/WETH Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.tertiary,
        },
        '0xC1E69B4b507DA07075460d0E297987D6A5Ce0aBf': {
          batchId: '6',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'WMATIC/WETH Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.quaternary,
        },
        // FASTGAS dedicated updaters
        '0xA9DA2c3a171e04D8a9c9A28e5b93D91167976502': {
          disabled: true,
          batchId: '13',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Fast Gas Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.gas.primary,
        },
        '0x7919c5a348C32c877003bA54aF7d5704aa315914': {
          disabled: true,
          batchId: '13',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Fast Gas Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.gas.secondary,
        },
        '0x7D0B754AC0258E7ceFc4A6F0bd8C89F518450B24': {
          disabled: true,
          batchId: '13',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Fast Gas Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.gas.tertiary,
        },
        '0x5F65Fe38b59bF79B80aAB40575c6516F03A02140': {
          disabled: true,
          batchId: '13',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Fast Gas Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.gas.quaternary,
        },
        // Compound III USDC interest rate dedicated updaters
        '0x873659d0E5972663E3B90aC345B7442CF68Ae369': {
          batchId: '14',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Interest Rate Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.primary,
        },
        '0x64aB10D023e50E187Ada5eDA0fD2D6FAa94BDab0': {
          batchId: '14',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Interest Rate Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.secondary,
        },
        '0x0335b5380470A35E30B5B64a61CBEF9bA6998459': {
          batchId: '14',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Interest Rate Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.tertiary,
        },
        '0x08F8912930b1c22B9471a167c0b43690549D7FC8': {
          batchId: '14',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Interest Rate Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.quaternary,
        },
        // Aave v3 USDC interest rate dedicated updaters
        '0xC9E4a0F1f1877Da9D99D1e92208f435Da984a937': {
          batchId: '15',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.primary,
        },
        '0xdead9EFd17A53bD071A9377dc9034Ce347dA40D7': {
          batchId: '15',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.secondary,
        },
        '0x2Dae43778848775F82D9027614202256B3cD8666': {
          batchId: '15',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.tertiary,
        },
        '0x87f7e6c93356DF82106289094A5C3900BE756ee0': {
          batchId: '15',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 USDC Interest Rate Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.quaternary,
        },
        // stMATIC/WMATIC dedicated updaters
        '0x07f7B0C95e90874A68A9C2411047B53270B10C3F': {
          batchId: '16',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'stMATIC/WMATIC Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.primary,
        },
        '0x13D9DB44DbA812A4c8445559D2fAd051bcc773B9': {
          batchId: '16',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'stMATIC/WMATIC Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.secondary,
        },
        '0xD7DD82ef4B8C8DC9599Fd13E85F4f08eE587C7db': {
          batchId: '16',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'stMATIC/WMATIC Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.tertiary,
        },
        '0x3918a8419d25fda800Dab90c5a8b2F3897Ae2d74': {
          batchId: '16',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'stMATIC/WMATIC Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.quaternary,
        },
        // Compound III USDC S&B dedicated updaters
        '0xCCF06Fd0D1a57bE24Ff9416383cCE7eF66cfb5BA': {
          batchId: '17',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Supply & Borrow Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.primary,
        },
        '0xc09739C0a1752b7E72dC77159C67C8f08C7A80e2': {
          batchId: '17',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Supply & Borrow Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.secondary,
        },
        '0x461528e8565334c435E63cE29c8cf98D3A980ccc': {
          batchId: '17',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Supply & Borrow Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.tertiary,
        },
        '0x776B66Ab05f6B1A9F956faD56C1c490186403ad7': {
          batchId: '17',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Compound III USDC Supply & Borrow Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.quaternary,
        },
        // Aave v3 S&B dedicated updaters
        '0x99Df46d02C13D887013ac8492270Ef7301d18784': {
          batchId: '18',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 Supply & Borrow Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.primary,
        },
        '0x479Ac776E89a6Fd9cdE537aAE9bFb35c6c1AB714': {
          batchId: '18',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 Supply & Borrow Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.secondary,
        },
        '0x6B9718E810852bCeA41A88bEef3A41887433F238': {
          batchId: '18',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 Supply & Borrow Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.tertiary,
        },
        '0x8903f3454361d8A4D9293aaAEA0bBB71E44A7aD4': {
          batchId: '18',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Aave v3 Supply & Borrow Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.lending.quaternary,
        },
        // Oku Trade dedicated updaters
        '0xB2763C605dF5961D4Dd3b5FeE8Aee5eA1D193096': {
          disabled: true,
          batchId: '19',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
          topUpAmount: ethers.utils.parseEther('100'),
          lowBalanceWarning: ethers.utils.parseEther('20'),
          lowBalanceDanger: ethers.utils.parseEther('10'),
        },
        '0x522B392A14E2BF03d2Ac76Ff6D0198C0bA776F65': {
          disabled: true,
          batchId: '19',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
          topUpAmount: ethers.utils.parseEther('100'),
          lowBalanceWarning: ethers.utils.parseEther('20'),
          lowBalanceDanger: ethers.utils.parseEther('10'),
        },
        '0xb96f7DCDA8bACB13898c577a62f5cc7bB95d7800': {
          disabled: true,
          batchId: '19',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
          topUpAmount: ethers.utils.parseEther('100'),
          lowBalanceWarning: ethers.utils.parseEther('20'),
          lowBalanceDanger: ethers.utils.parseEther('10'),
        },
        '0x643C2E5562fb195D40Cc34af50f6596F605d3a3C': {
          disabled: true,
          batchId: '19',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
          topUpAmount: ethers.utils.parseEther('100'),
          lowBalanceWarning: ethers.utils.parseEther('20'),
          lowBalanceDanger: ethers.utils.parseEther('10'),
        },
        // Test updaters
        '0xB16ec05804D01dBa3937043ad43BaFb35Ca35389': {
          batchId: '12',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Test Primary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.primary,
        },
        '0xd6Ff440A509dC63357A881D9CeBeF70E113FA026': {
          batchId: '12',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Test Secondary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.secondary,
        },
        '0xE7D4BcD53D3E4F1a2Ed7732b1ac10e5871842c35': {
          batchId: '12',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Test Tertiary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.tertiary,
        },
        '0x1Ba6664650D721758E93F46F6A382563eca3594d': {
          batchId: '12',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Test Quaternary Updater',
          ...UPDATER_BALANCE_CONFIGS.polygon.dex.quaternary,
        },
        // Adrastia Pyth updaters
        '0x915223a8eA7E1c49009530580A3267d94c5212Fd': {
          batchId: '20',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Adrastia Pyth Primary Updater',
        },
        '0x1DF75fC487909CB0642B6053D7F30B643CEB67f7': {
          batchId: '20',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Adrastia Pyth Secondary Updater',
        },
        '0x1bFbB21F775D52CE9a62d5Df4c4b838Dd29E9fB8': {
          batchId: '20',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Adrastia Pyth Tertiary Updater',
        },
        '0x667E1A8897d6302F10Aa4018D4Ec77Def2af878b': {
          batchId: '20',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Adrastia Pyth Quaternary Updater',
        },
      },
      strategies: {
        validation: {
          [ethers.constants.AddressZero]: {
            name: 'None',
            desc: 'No (additional) validation is performed.',
          },
        },
        aggregation: {
          '0x021E3bD203144ae330A766d4076040a48Fea6122': {
            name: 'Median',
            desc: 'Computes the median %PRICE_SLOT%, sum of %TOKEN_LIQUIDITY_SLOT%, and sum of %QUOTE_TOKEN_LIQUIDITY_SLOT%.',
          },
          '0x90C8E14d36bfc6ea6c871F5874eE095631d4eDC6': {
            name: 'Minimum',
            desc: 'Computes the minimum %PRICE_SLOT%, sum of %TOKEN_LIQUIDITY_SLOT%, and sum of %QUOTE_TOKEN_LIQUIDITY_SLOT%.',
          },
          '0x92Eb6895550Fd2EFc1519De69f8b85A819A1fDC1': {
            name: 'Maximum',
            desc: 'Computes the maximum %PRICE_SLOT%, sum of %TOKEN_LIQUIDITY_SLOT%, and sum of %QUOTE_TOKEN_LIQUIDITY_SLOT%.',
          },
        },
      },
    },
    moonbeam: {
      name: 'Moonbeam',
      chainId: Moonbeam.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('100.0'),
      defaultLowBalanceWarning: ethers.utils.parseEther('20.0'),
      defaultLowBalanceDanger: ethers.utils.parseEther('10.0'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0x0F2421892F7067deA33E3617D7cb281DBF759AC2': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0xBCE4a694A79dD1862b621B7d1a6b335607382031': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0x649F017Ec3e8E305AB2b403C98200C39AbB49A6D': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0x867b668E17a546E277a2adA3648b743f8A72B13d': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    boba: {
      name: 'Boba',
      chainId: Boba.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0x6beEBcBeE1d273C1D477C40e5535Fb134C21cb7d': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0x13ba9621b3E8b043B0ADe2339437065bb1c1BFc0': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0x0b12f7F7da1e03704F7eD3706ba40E8ddB5C2d0D': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0x8f39c5901dd78E34924B86C0aDE83Bb5C4c6f1BB': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    scroll: {
      name: 'Scroll',
      chainId: Scroll.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0x34aE64E2c02d808626B472387F092501fA6dd7a2': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0x7a16E33Ecc120543F8da77D882585645F1C06DFD': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0xD884BF35092157dDca751e0f6175847E59350C8F': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0x03ca68308bE8B0dE75b2df6F405eb0FC3AeA6944': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    zkSync: {
      name: 'ZkSync Era',
      chainId: ZkSync.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0xAD43eedb268378E25959046D4DD1eE1675df680D': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0xbd69333854Cb307800ACc96089f46FB9127f1e0E': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0x2319e4d3358E1e9E284E1F354Bb6572ad2fc0056': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0x57eE6A17Cae0C46FE996BFda32e6E12edA247586': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    filecoin: {
      name: 'Filecoin',
      chainId: Filecoin.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('2.0'),
      defaultLowBalanceWarning: ethers.utils.parseEther('1.0'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.5'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0x664D20e19D520fCDa95Eb4B7790614CA25C16351': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0x046C8a0acBd5738CeF298C2b26C0bC7fa260660e': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0x4BC4AE3DaD28435718DF38F8A983BADf875CCe8c': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0x5Fefa90f0058384BB6b2f775E9d829b20bB3FF81': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    rootstock: {
      name: 'Rootstock',
      chainId: Rootstock.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.001'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.0005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.00025'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0x352E7851B3B1C10E56f91176F07514539aD3aDb7': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0xB7de77e255E2EB75C56Ff311DBE9d5561e2B457b': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0xe6d8cB3974bB04321ef656c3188a3EBd2ae96489': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0xF82Ffd2ED619bCeb091D2fee9929755AFc589187': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    base: {
      name: 'Base',
      chainId: Base.chainId,
      maxFilterBlocks: Math.ceil((3 * 30 * 24 * 60 * 60) / 2), // 3 months
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
        '0xCAd925d756Dde186f4093a1324caa47dCe0573A3': {
          name: 'Adrastia Multisig',
        },
      },
      tokens: {
        '0x4200000000000000000000000000000000000006': {
          name: 'Wrapped Ether',
          symbol: 'WETH',
          decimals: 18,
          icon: 'weth',
        },
        '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913': {
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          icon: 'usdc',
        },
        '0xCfA3Ef56d303AE4fAabA0592388F19d7C3399FB4': {
          name: 'eUSD',
          symbol: 'eUSD',
          decimals: 18,
          icon: 'eusd',
        },
        '0xCc7FF230365bD730eE4B352cC2492CEdAC49383e': {
          name: 'High Yield USD',
          symbol: 'hyUSD',
          decimals: 18,
          icon: 'hyusd',
        },
        '0xaB36452DbAC151bE02b16Ca17d8919826072f64a': {
          name: 'Reserve Rights',
          symbol: 'RSR',
          decimals: 18,
          icon: 'rsr',
        },
        '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22': {
          name: 'Coinbase Wrapped Staked ETH',
          symbol: 'cbETH',
          decimals: 18,
          icon: 'cbeth',
        },
        '0xc1CBa3fCea344f92D9239c08C0568f6F2F0ee452': {
          name: 'Wrapped Liquid Staked Ether 2.0',
          symbol: 'wstETH',
          decimals: 18,
          icon: 'wsteth',
        },
        '0xCb327b99fF831bF8223cCEd12B1338FF3aA322Ff': {
          name: 'Based ETH',
          symbol: 'bsdETH',
          decimals: 18,
          icon: 'bsdeth',
        },
        '0x2416092f143378750bb29b79eD961ab195CcEea5': {
          name: 'Renzo Restaked ETH',
          symbol: 'ezETH',
          decimals: 18,
          icon: 'ezeth',
        },
        '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A': {
          name: 'Wrapped eETH',
          symbol: 'weETH.base',
          decimals: 18,
          icon: 'weeth',
        },
        '0x940181a94A35A4569E4529A3CDfB74e38FD98631': {
          name: 'Aerodrome',
          symbol: 'AERO',
          decimals: 18,
          icon: 'aero',
        },
      },
      oracles: {
        '0x52C7AC0B95f2e73506777a0073f3f2562e1cC6eD': {
          hide: true,
          name: 'Ionic Total Supply & Borrow Accumulator',
          desc: '',
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 50, // 0.5%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
            '0xCfA3Ef56d303AE4fAabA0592388F19d7C3399FB4', // eUSD
            '0xCc7FF230365bD730eE4B352cC2492CEdAC49383e', // hyUSD
            '0xaB36452DbAC151bE02b16Ca17d8919826072f64a', // RSR
            '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22', // cbETH
            '0xc1CBa3fCea344f92D9239c08C0568f6F2F0ee452', // wstETH
            '0xCb327b99fF831bF8223cCEd12B1338FF3aA322Ff', // bsdETH
            '0x2416092f143378750bb29b79eD961ab195CcEea5', // ezETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.base
            '0x940181a94A35A4569E4529A3CDfB74e38FD98631', // AERO
          ],
          dataPoints: supplyAndBorrow,
        },
        '0x8e955737E2ACB12d8b0F2D765c7D9EA125547B01': {
          hide: v4FeedsHidden,
          name: 'Ionic Total Supply & Borrow Oracle',
          desc: 'v4.9.0, GM-TWA, 24-hour period, 2x granularity',
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 50, // 0.5%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
            '0xCfA3Ef56d303AE4fAabA0592388F19d7C3399FB4', // eUSD
            '0xCc7FF230365bD730eE4B352cC2492CEdAC49383e', // hyUSD
            '0xaB36452DbAC151bE02b16Ca17d8919826072f64a', // RSR
            '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22', // cbETH
            '0xc1CBa3fCea344f92D9239c08C0568f6F2F0ee452', // wstETH
            '0xCb327b99fF831bF8223cCEd12B1338FF3aA322Ff', // bsdETH
            '0x2416092f143378750bb29b79eD961ab195CcEea5', // ezETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.base
            '0x940181a94A35A4569E4529A3CDfB74e38FD98631', // AERO
          ],
          dataPoints: supplyAndBorrow,
        },
        '0x0D37164F1b7aBC350f8f933afC866270f47F3D99': {
          hide: true,
          name: 'Ionic Utilization & Error Accumulator',
          desc: '',
          type: 'valueAndError',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Utilization and Error Chart',
            decimals: 16,
          },
          updating: {
            liquidity: {
              bips: 50, // 0.5%
              heartbeat: 1 * 60 * 60, // 1 hours
            },
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
            '0x940181a94A35A4569E4529A3CDfB74e38FD98631', // AERO
          ],
          dataPoints: utilizationAndError,
        },
        '0x0E60d9b3fAEcC0BfF51A8616eD7280F4AF8886ab': {
          hide: false,
          name: 'Ionic Utilization & Error Oracle',
          desc: 'v4.9.0, AM-TWA, 6-hour period, 3x granularity',
          type: 'valueAndError',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Utilization and Error Chart',
            decimals: 16,
          },
          updating: {
            liquidity: {
              bips: 50, // 0.5%
              heartbeat: 1 * 60 * 60, // 1 hours
            },
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
            '0x940181a94A35A4569E4529A3CDfB74e38FD98631', // AERO
          ],
          dataPoints: utilizationAndError,
        },
      },
      controllers: {
        '0x5718537B4c33354f7adf5369286B5751A0cb1FD8': {
          hide: false,
          name: 'Ionic Interest Rate Provider',
          desc: '',
          type: 'historicalRatesComputer',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 16,
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
            '0x940181a94A35A4569E4529A3CDfB74e38FD98631', // AERO
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0x01789C1E9C67EaB5E6e95e5E97fE943997B410ba': {
          hide: false,
          name: 'Ionic Interest Rate Controller',
          desc: '',
          type: 'rateController',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 16,
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
            '0x940181a94A35A4569E4529A3CDfB74e38FD98631', // AERO
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0xCFCdc27Cedd46550dA98118a21424B22ebC32A69': {
          hide: false,
          name: 'Ionic Sloped Interest Rate Computer',
          desc: '',
          type: 'slopedOracleMutationComputer',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 16,
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
            '0x940181a94A35A4569E4529A3CDfB74e38FD98631', // AERO
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0x27e37a91456f02fe4B91D10F3d3636631E25ba01': {
          name: 'Ionic PID Controller',
          desc: '',
          type: 'pidController',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 16,
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
            '0x940181a94A35A4569E4529A3CDfB74e38FD98631', // AERO
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
      },
      transformers: {
        inputAndError: {
          '0x92e67491DAC364568861cc88Ac5Dd33e3E6dC874': {
            name: '4x Negative Error Scalar',
            desc: 'Multiplies negative errors by 4.',
          },
        },
      },
      updaters: {
        // Oku Trade dedicated updaters (batch 0)
        '0xaE59dD6c19214EA133469dB7FFCDb0690D7B8F95': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
          disabled: true,
        },
        '0x7ef93C08dD493b618aba688D3938Bef39fb93488': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
          disabled: true,
        },
        '0x4EE80f1230B3E6afc3cC6D423B971CD6a528912d': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
          disabled: true,
        },
        '0xf399D4FFdC481D6e83855e639d858C1DbAAcF986': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
          disabled: true,
        },
        // Ionic oracle updaters (batch 1)
        '0x82c1b6C2f11FC1c740628a537a5674432Bc3b920': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Primary Updater',
        },
        '0x6edfdf86f9817c36411146D0A62b95e387aD4A79': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Secondary Updater',
        },
        '0x21abc97395161C7aB0b300b49c07178202713e4B': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Tertiary Updater',
        },
        '0xf1AFfC280d0E49fb2D6cfb7DEEe25656f00f3dE0': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Quaternary Updater',
        },
        // Ionic Pyth updaters (batch 2)
        '0xd598B47AC58932373752561C351cA0951D4Af22f': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Pyth Feeds Primary Updater',
        },
        '0xf14895CF4D4722B9470e3c76737de6f3B6176538': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Pyth Feeds Secondary Updater',
        },
        '0x720a95494cF8931B2392836d4aDB23bF2DF2f482': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Pyth Feeds Tertiary Updater',
        },
        '0x7AEa317F636095bE5b2f84aEA1Fe5578262D5958': {
          batchId: '2',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Pyth Feeds Quaternary Updater',
        },
      },
    },
    gnosis: {
      name: 'Gnosis',
      chainId: Gnosis.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {},
    },
    metis: {
      name: 'Metis',
      chainId: Metis.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {},
    },
    mantaPacific: {
      name: 'Manta Pacific',
      chainId: MantaPacific.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters (batch 0)
        '0x024d0f842d1B231EF91f83fF385C2D882F75e447': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0x244750f889B7cCC9D01680B9E8EA092B9616317D': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0xd5f83c7Fa4BE9E2970946D201D41cB9cE4a33662': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0xfD4BeF5d2b67276881497392Ac39d30Facef0D98': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    blast: {
      name: 'Blast',
      chainId: Blast.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters (batch 0)
        '0x75DcF7d9643F74930BF285Aec04Bdd38BBa9D29E': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0xe1D59D4a19B2589BB48071b2fBAf8d212B7BFE7A': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0x7812D3abA246e884FB4292A5F4d5f41294272dCd': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0x44Cd9cBDC6c3164808b8CFEB6a8D9121da9f33ec': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    mode: {
      name: 'Mode',
      chainId: Mode.chainId,
      maxFilterBlocks: (86400 / 2) * 28, // 28 days
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
        '0x8Fba84867Ba458E7c6E2c024D2DE3d0b5C3ea1C2': {
          name: 'Ionic Protocol Multisig',
        },
        '0x697585E92B13E1988FFfDFFB2D14873992724E37': {
          name: 'Adrastia Multisig',
        },
      },
      tokens: {
        '0x4200000000000000000000000000000000000006': {
          name: 'Wrapped Ether',
          symbol: 'WETH',
          decimals: 18,
          icon: 'weth',
        },
        '0xd988097fb8612cc24eec14542bc03424c656005f': {
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          icon: 'usdc',
        },
        '0xf0f161fda2712db8b566946122a5af183995e2ed': {
          name: 'Tether USD',
          symbol: 'USDT',
          decimals: 6,
          icon: 'usdt',
        },
        '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf': {
          name: 'Wrapped Bitcoin',
          symbol: 'WBTC',
          decimals: 8,
          icon: 'wbtc',
        },
        '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5': {
          name: 'Wrapped eETH (Old)',
          symbol: 'weETH',
          decimals: 18,
          icon: 'weeth',
        },
        '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A': {
          name: 'Wrapped eETH',
          symbol: 'weETH.mode',
          decimals: 18,
          icon: 'weeth',
        },
        '0x2416092f143378750bb29b79ed961ab195cceea5': {
          name: 'Renzo Restaked ETH',
          symbol: 'ezETH',
          decimals: 18,
          icon: 'ezeth',
        },
        '0x80137510979822322193fc997d400d5a6c747bf7': {
          name: 'StakeStone Ether',
          symbol: 'STONE',
          decimals: 18,
          icon: 'stone',
        },
        '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd': {
          name: 'Wrapped Kelp DAO Restaked ETH',
          symbol: 'wrsETH',
          decimals: 18,
          icon: 'wrseth',
        },
        '0x59889b7021243dB5B1e065385F918316cD90D46c': {
          name: 'Merlin BTC',
          symbol: 'M-BTC',
          decimals: 18,
          icon: 'mbtc',
        },
        '0xDfc7C877a950e49D2610114102175A06C2e3167a': {
          name: 'Mode',
          symbol: 'MODE',
          decimals: 18,
          icon: 'mode',
        },
        '0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2': {
          name: 'Staked USDe',
          symbol: 'sUSDe',
          decimals: 18,
          icon: 'susde',
        },
        '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34': {
          name: 'USDe',
          symbol: 'USDe',
          decimals: 18,
          icon: 'usde',
        },
        '0x93a397fb0db16BA4bb045a4C08Ee639Cb5639495': {
          name: 'dMBTC',
          symbol: 'dMBTC',
          decimals: 18,
          icon: 'dmbtc',
        },
        '0x3f51c6c5927B88CDEc4b61e2787F9BD0f5249138': {
          name: 'Mode Savings Dai',
          symbol: 'msDAI',
          decimals: 18,
          icon: 'sdai',
        },
      },
      oracles: {
        '0xaEfF434632c500786f64eBD1dF6C8567665801Da': {
          hide: true,
          name: 'Ionic Total Supply & Borrow Accumulator',
          desc: '',
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 50, // 0.5%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
            '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34', // USDe
            '0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2', // sUSDe
            '0x93a397fb0db16BA4bb045a4C08Ee639Cb5639495', // dMBTC
            '0x3f51c6c5927B88CDEc4b61e2787F9BD0f5249138', // msDAI
          ],
          dataPoints: supplyAndBorrow,
        },
        '0xd850F64Eda6a62d625209711510f43cD49Ef8798': {
          hide: v4FeedsHidden,
          name: 'Ionic Total Supply & Borrow Oracle',
          desc: 'v4.7.1, GM-TWA, 24-hour period, 2x granularity',
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          maxFilterBlocks: 5_000_000,
          display: {
            chart: {
              annotationsX: {
                '0x4200000000000000000000000000000000000006': {
                  1723002461000: {
                    label: 'Prudentia PID Controller activated',
                    txHash: '0xce82b4b07fd9cd4809a130d10bbd06d8950b558240379ba03cb37bf2ebc596d8',
                  },
                },
                '0xd988097fb8612cc24eec14542bc03424c656005f': {
                  1723002461000: {
                    label: 'Prudentia PID Controller activated',
                    txHash: '0xce82b4b07fd9cd4809a130d10bbd06d8950b558240379ba03cb37bf2ebc596d8',
                  },
                },
                '0xf0f161fda2712db8b566946122a5af183995e2ed': {
                  1723002461000: {
                    label: 'Prudentia PID Controller activated',
                    txHash: '0xce82b4b07fd9cd4809a130d10bbd06d8950b558240379ba03cb37bf2ebc596d8',
                  },
                },
              },
            },
          },
          updating: {
            liquidity: {
              bips: 50, // 0.5%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
            '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34', // USDe
            '0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2', // sUSDe
            '0x93a397fb0db16BA4bb045a4C08Ee639Cb5639495', // dMBTC
            '0x3f51c6c5927B88CDEc4b61e2787F9BD0f5249138', // msDAI
          ],
          dataPoints: supplyAndBorrow,
        },
        '0x6f98C0C78f4241ea61F97b559fD3C37B9F32d728': {
          hide: true,
          name: 'Ionic Utilization & Error Accumulator',
          desc: '',
          type: 'valueAndError',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Utilization and Error Chart',
            decimals: 16,
          },
          updating: {
            liquidity: {
              bips: 50, // 0.5%
              heartbeat: 1 * 60 * 60, // 1 hours
            },
          },
          tokens: [
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0x4200000000000000000000000000000000000006', // WETH
          ],
          dataPoints: utilizationAndError,
        },
        '0x2a0755Ca1EbcB9c37C883c09DE5202b3cc7b7470': {
          hide: false,
          name: 'Ionic Utilization & Error Oracle',
          desc: 'v4.8.1, AM-TWA, 6-hour period, 3x granularity',
          type: 'valueAndError',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            chartTitle: 'Utilization and Error Chart',
            decimals: 16,
          },
          updating: {
            liquidity: {
              bips: 50, // 0.5%
              heartbeat: 1 * 60 * 60, // 1 hours
            },
          },
          tokens: [
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0x4200000000000000000000000000000000000006', // WETH
          ],
          dataPoints: utilizationAndError,
        },
        '0x106ac811C385024821539B91D2A9270A0B7258A6': {
          hide: true,
          name: 'Ionic Native Total Supply & Borrow Accumulator',
          desc: '',
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 100, // 1%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xDfc7C877a950e49D2610114102175A06C2e3167a', // MODE
          ],
          dataPoints: supplyAndBorrow,
        },
        '0x9dEaF831a30ADa08140C7614ff3fa99e37218743': {
          hide: true,
          name: 'Ionic Native Total Supply & Borrow Oracle',
          desc: 'v4.7.1, GM-TWA, 24-hour period, 2x granularity',
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 100, // 1%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xDfc7C877a950e49D2610114102175A06C2e3167a', // MODE
          ],
          dataPoints: supplyAndBorrow,
        },
        '0x8e955737E2ACB12d8b0F2D765c7D9EA125547B01': {
          hide: true,
          name: 'Ionic Total Supply & Borrow Oracle (Old)',
          desc: 'v4.5.0, GM-TWA, 24-hour period, 2x granularity',
          type: 'supplyAndBorrow',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          updating: {
            liquidity: {
              bips: 100, // 1%
              heartbeat: 86400, // in seconds, 1 day
            },
          },
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
          ],
          dataPoints: supplyAndBorrow,
        },
      },
      controllers: {
        '0x09D7EC8d023859b48a634d425bfEf29622272aFa': {
          hide: false,
          name: 'Ionic Interest Rate Provider',
          desc: '',
          type: 'historicalRatesComputer',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 16,
          },
          tokens: [
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0x4200000000000000000000000000000000000006', // WETH
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0x4B84b0973de669Bc1a9B1cf63bFdFEfD71503063': {
          hide: false,
          name: 'Ionic Interest Rate Controller',
          desc: '',
          type: 'rateController',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 16,
          },
          tokens: [
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0x4200000000000000000000000000000000000006', // WETH
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0x786881A6d1d3337d51c5bE56362452A4F265CB68': {
          hide: false,
          name: 'Ionic Sloped Interest Rate Computer',
          desc: '',
          type: 'slopedOracleMutationComputer',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 16,
          },
          tokens: [
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0x4200000000000000000000000000000000000006', // WETH
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0xC40753877CfeF6f50E13695395c58357505719F8': {
          name: 'Ionic PID Controller',
          desc: '',
          type: 'pidController',
          decimals: 18,
          quoteUnit: {
            symbol: '',
          },
          display: {
            rateSuffix: '%',
            decimals: 16,
          },
          tokens: [
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0x4200000000000000000000000000000000000006', // WETH
          ],
          dataPoints: prudentiaInterestRates,
          maxFilterBlocks: 15000000,
        },
        '0x425Ed58c3B836B1c5a073ab5dae3ee6c94336B21': {
          name: 'Ionic Supply Cap Controller',
          desc: '',
          type: 'capController',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
            '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34', // USDe
            '0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2', // sUSDe
            '0x93a397fb0db16BA4bb045a4C08Ee639Cb5639495', // dMBTC
            '0x3f51c6c5927B88CDEc4b61e2787F9BD0f5249138', // msDAI
          ],
          dataPoints: ratesSupplyCap,
        },
        '0xB31E2b40cC81A653f62043a8A2b35C7a46a64fB0': {
          hide: false,
          name: 'Ionic Supply Cap Computer',
          desc: '',
          type: 'oracleMutationComputer',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
            '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34', // USDe
            '0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2', // sUSDe
            '0x93a397fb0db16BA4bb045a4C08Ee639Cb5639495', // dMBTC
            '0x3f51c6c5927B88CDEc4b61e2787F9BD0f5249138', // msDAI
          ],
          dataPoints: ratesSupplyCap,
        },
        '0x3060759F0D0BF60c373f9057BB1269c28Bd5Bb66': {
          name: 'Ionic Borrow Cap Controller',
          desc: '',
          type: 'capController',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
            '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34', // USDe
            '0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2', // sUSDe
            '0x93a397fb0db16BA4bb045a4C08Ee639Cb5639495', // dMBTC
            '0x3f51c6c5927B88CDEc4b61e2787F9BD0f5249138', // msDAI
          ],
          dataPoints: ratesBorrowCap,
        },
        '0xcAB0478549C38acc1b157bd5734364e3b0034024': {
          hide: false,
          name: 'Ionic Borrow Cap Computer',
          desc: '',
          type: 'oracleMutationComputer',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
            '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34', // USDe
            '0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2', // sUSDe
            '0x93a397fb0db16BA4bb045a4C08Ee639Cb5639495', // dMBTC
            '0x3f51c6c5927B88CDEc4b61e2787F9BD0f5249138', // msDAI
          ],
          dataPoints: ratesBorrowCap,
        },
        // Ionic Native
        '0x361c6791dB095b7A22F4337b28471c49454531C7': {
          hide: true,
          name: 'Ionic Native Supply Cap Controller',
          desc: '',
          type: 'capController',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xDfc7C877a950e49D2610114102175A06C2e3167a', // MODE
          ],
          dataPoints: ratesSupplyCap,
        },
        '0x0034bF3dFfEEcca5c91270d80B1b3e2fDebCd829': {
          hide: true,
          name: 'Ionic Native Supply Cap Computer',
          desc: '',
          type: 'oracleMutationComputer',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xDfc7C877a950e49D2610114102175A06C2e3167a', // MODE
          ],
          dataPoints: ratesSupplyCap,
        },
        '0x0A7072c28a23B831292b863D3412ABE897b954f7': {
          hide: true,
          name: 'Ionic Native Borrow Cap Controller',
          desc: '',
          type: 'capController',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xDfc7C877a950e49D2610114102175A06C2e3167a', // MODE
          ],
          dataPoints: ratesBorrowCap,
        },
        '0xB56803e2915EfAB6D5aaf50F9Fb152f08D3eb13c': {
          hide: true,
          name: 'Ionic Native Borrow Cap Computer',
          desc: '',
          type: 'oracleMutationComputer',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xDfc7C877a950e49D2610114102175A06C2e3167a', // MODE
          ],
          dataPoints: ratesBorrowCap,
        },
        // Ionic (Old)
        '0xeDA407758c98A4bC34763e6A9DFF7313f817C7d6': {
          hide: true,
          name: 'Ionic Supply Cap Controller (Old)',
          desc: '',
          type: 'capController',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
          ],
          dataPoints: ratesSupplyCap,
        },
        '0x06F8968411067d9122B6d4Ec7Bd3d69A220D504a': {
          hide: true,
          name: 'Ionic Supply Cap Computer (Old)',
          desc: '',
          type: 'oracleMutationComputer',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
          ],
          dataPoints: ratesSupplyCap,
        },
        '0x516E419eBFAe70882148235ea197ee165f9c1048': {
          hide: true,
          name: 'Ionic Borrow Cap Controller (Old)',
          desc: '',
          type: 'capController',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
            '0x59889b7021243dB5B1e065385F918316cD90D46c', // M-BTC
          ],
          dataPoints: ratesBorrowCap,
        },
        '0x2dfA81f1C5d95463d2A03fbb0165eCb502F195C3': {
          hide: true,
          name: 'Ionic Borrow Cap Computer (Old)',
          desc: '',
          type: 'oracleMutationComputer',
          decimals: 4,
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [
            '0x4200000000000000000000000000000000000006', // WETH
            '0xd988097fb8612cc24eec14542bc03424c656005f', // USDC
            '0xf0f161fda2712db8b566946122a5af183995e2ed', // USDT
            '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf', // WBTC
            '0x028227c4dd1e5419d11bb6fa6e661920c519d4f5', // weETH
            '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A', // weETH.mode
            '0x2416092f143378750bb29b79ed961ab195cceea5', // ezETH
            '0x80137510979822322193fc997d400d5a6c747bf7', // STONE
            '0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd', // wrsETH
          ],
          dataPoints: ratesBorrowCap,
        },
        '0x127EE750A56A64c39c2d7abb30d221c51cEDBe12': {
          hide: true,
          name: 'Nil Price Accumulator',
          desc: '',
          decimals: 0,
          type: 'dexPriceAndLiquidity',
          quoteUnit: {
            symbol: '',
          },
          display: {},
          tokens: [],
          dataPoints: priceDataPoints,
        },
      },
      transformers: {
        inputAndError: {
          '0x9E3a9F198F6d5080c92Dbca9a365dAa1FFd5cF87': {
            name: '4x Negative Error Scalar',
            desc: 'Multiplies negative errors by 4.',
          },
        },
      },
      updaters: {
        // Ionic dedicated updaters
        '0xbbE6AA003532bb50278d80E802Fa0857609ac862': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Primary Updater',
        },
        '0x5721fa839c2192e5eF9E7BbBA7E60945817FbdD9': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Secondary Updater',
        },
        '0xF02166544655582aCaF204da13BC3d9d20127493': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Tertiary Updater',
        },
        '0xCF43124af4C23432Bd9566b0031216c430D38074': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Quaternary Updater',
        },
        // Ionic Pyth updaters (batch 1)
        '0x29414C32133e3863716440000Ef9B15B54f7d605': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Pyth Feeds Primary Updater',
        },
        '0xECe425eD71eC226624A32eCfc8b31f454F3e02f9': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Pyth Feeds Secondary Updater',
        },
        '0x0B02e279d0eD8E0388509A889160E17BcC55BE0a': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Pyth Feeds Tertiary Updater',
        },
        '0x188f728d91B89213aeB1911FF940F73c225Fc80b': {
          batchId: '1',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Ionic Pyth Feeds Quaternary Updater',
        },
      },
    },
    linea: {
      name: 'Linea',
      chainId: Linea.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0x9eC687171f68e9498c55Bec7d748Dd1a141BA3E5': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0xC0bA7e9Bfd936d4EB2D680b262e89856503E7575': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0x07952E49B9fdE66f227aa5C0a32e2da3B85B21Fb': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0x15Cd932263C8151E896115c4Bb4872C56FCAE812': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    taiko: {
      name: 'Taiko',
      chainId: Taiko.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.005'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.003'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.002'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0xdF3c723E854630E60336E78B7Ad6E96BAab0A640': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0xE1ed1C1D2981aF8e0BFd1b2C51Ad483cCEC61B39': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0xe7C5d242F0bBe0af91a42d7a1d2280DA7255d5Be': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0xD4d3fE8B5c1c2f7F3B792Ecbc07496F4D91A633c': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    sei: {
      name: 'Sei',
      chainId: Sei.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('50'),
      defaultLowBalanceWarning: ethers.utils.parseEther('20'),
      defaultLowBalanceDanger: ethers.utils.parseEther('10'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0xBf084CB588e0397e76faF752Ce2cd0A0A033eB54': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0x74268738DAf3Fd23b6FaB395eAfA67C10231ff9E': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0xbca0F17548A41f1431DeA50921bD7657dbC86E99': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0x592e7B97546A665dEb0064eB5303B640b7aCcb4a': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    mantle: {
      name: 'Mantle',
      chainId: Mantle.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('50'),
      defaultLowBalanceWarning: ethers.utils.parseEther('20'),
      defaultLowBalanceDanger: ethers.utils.parseEther('10'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0x9f311fc70ca88fb62Fd8690537A2c1c8973efacC': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0xb115FD767a73ecac43430cc3ff036d84955F09f5': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0x56819B05A6491B17145912AD7C3CC02f0B7b8aa8': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0xDFC7dB54AE4e4A251885145cee7475586cc829F1': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    lisk: {
      name: 'Lisk',
      chainId: Lisk.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.01'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.005'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.003'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        // Oku Trade dedicated updaters
        '0x97994152aae354809841eC528AD538B65C742920': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Primary Updater',
        },
        '0xdC2F11e7E0Fd88bFdcf6183e0562495aF9Ddd771': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Secondary Updater',
        },
        '0x85AEfBC934AeCE8148d912E42254C048a44fa04f': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Tertiary Updater',
        },
        '0x26Be87B73D76d53302c2344A70435A41C0a4C980': {
          batchId: '0',
          owner: 'TRILEZ SOFTWARE INC.',
          name: 'Oku Trade Quaternary Updater',
        },
      },
    },
    hardhat: {
      name: 'Hardhat',
      chainId: Hardhat.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.1'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.05'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.02'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {},
      oracles: {},
      updaters: {
        '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266': {
          batchId: '0',
          owner: '-',
          name: 'Hardhat Account 1',
        },
      },
    },
    arbitrumSepolia: {
      name: 'Arbitrum Sepolia',
      chainId: ArbitrumSepolia.chainId,
      maxFilterBlocks: 9990,
      defaultTopUpAmount: ethers.utils.parseEther('0.1'),
      defaultLowBalanceWarning: ethers.utils.parseEther('0.05'),
      defaultLowBalanceDanger: ethers.utils.parseEther('0.02'),
      addresses: {
        ...globalAddresses,
      },
      tokens: {
        '0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d': {
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          icon: 'usdc',
        },
        '0x980B62Da83eFf3D4576C647993b0c1D7faf17c73': {
          name: 'Wrapped Ether',
          symbol: 'WETH',
          decimals: 18,
          icon: 'weth',
        },
      },
      oracles: {},
      updaters: {},
      automatos: {
        registryFactory: '0x361c6791dB095b7A22F4337b28471c49454531C7',
        billingTokens: [
          '0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d',
          '0x980B62Da83eFf3D4576C647993b0c1D7faf17c73',
        ],
        registries: {
          '0xB479CdaDE9ce53efDC9997167E4075A1AB303599': {
            verified: true,
            servicedBy: 'TRILEZ SOFTWARE INC.',
          },
        },
      },
    },
  },
};

export default config;

export type { TokenConfig, OracleConfig, ChainConfig, AdrastiaConfig, DataPointsConfig };
